import React from 'react';

interface MicrosoftButtonProps {
  onClick: () => void;
  text?: string;
  disabled?: boolean;
  theme?: 'light' | 'dark';
}

// CSS styles according to Microsoft branding guidelines
const styles = `
.ms-signin-button {
  border: 1px solid #8C8C8C;
  background-color: white;
  height: 41px;
  display: flex;
  border-radius: 2px;
  align-items: center;
  padding: 0 12px;
  font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: background-color .2s, border-color .2s, box-shadow .2s;
  cursor: pointer;
  width: fit-content;
  min-width: min-content;
  max-width: 400px;
  position: relative;
  overflow: hidden;
}

.ms-signin-button.dark {
  background-color: #2F2F2F;
  border-color: #2F2F2F;
}

.ms-signin-button:hover {
  background-color: #f2f2f2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.ms-signin-button.dark:hover {
  background-color: #3a3a3a;
}

.ms-signin-button:active {
  background-color: #ebebeb;
}

.ms-signin-button.dark:active {
  background-color: #2a2a2a;
}

.ms-signin-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  box-shadow: none;
}

.ms-signin-button .ms-logo {
  height: 20px;
  width: 20px;
  margin-right: 12px;
  flex-shrink: 0;
}

.ms-signin-button .ms-text {
  flex-grow: 1;
  color: #5E5E5E;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-signin-button.dark .ms-text {
  color: #FFFFFF;
}
`;

// Official Microsoft logo as a data URL (base64 encoded)
const msLogoLight = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMyAyMyI+PHBhdGggZmlsbD0iI2YzZjNmMyIgZD0iTTAgMGgyM3YyM0gweiIvPjxwYXRoIGZpbGw9IiNmMzUzMjUiIGQ9Ik0xIDFoMTB2MTBIMXoiLz48cGF0aCBmaWxsPSIjODFiYzA2IiBkPSJNMTIgMWgxMHYxMEgxMnoiLz48cGF0aCBmaWxsPSIjMDVhNmYwIiBkPSJNMSAxMmgxMHYxMEgxeiIvPjxwYXRoIGZpbGw9IiNmZmJhMDgiIGQ9Ik0xMiAxMmgxMHYxMEgxMnoiLz48L3N2Zz4=";

// Add styles to document when component mounts
function useMicrosoftButtonStyles() {
  React.useEffect(() => {
    // Check if styles are already added to avoid duplicates
    if (!document.getElementById('microsoft-button-styles')) {
      const styleElement = document.createElement('style');
      styleElement.id = 'microsoft-button-styles';
      styleElement.innerHTML = styles;
      document.head.appendChild(styleElement);
      
      return () => {
        const styleElement = document.getElementById('microsoft-button-styles');
        if (styleElement) {
          document.head.removeChild(styleElement);
        }
      };
    }
  }, []);
}

const MicrosoftButton: React.FC<MicrosoftButtonProps> = ({
  onClick,
  text = 'Sign in with Microsoft',
  disabled = false,
  theme = 'light'
}) => {
  useMicrosoftButtonStyles();

  return (
    <button 
      type="button" 
      className={`ms-signin-button ${theme === 'dark' ? 'dark' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={msLogoLight} alt="Microsoft logo" className="ms-logo" />
      <span className="ms-text">{text}</span>
    </button>
  );
};

export default MicrosoftButton; 