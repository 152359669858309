import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useCurrentSourceSubTab = () => {
  const queryClient = useQueryClient();

  const { data: currentSourceSubTab } = useQuery<string, Error>({
    queryKey: ['current', 'subtab', 'sources'],
    queryFn: () => Promise.resolve('websites'),
    enabled: false,
    initialData: 'websites',
  });

  const updateCurrentSourceSubTab = (subtab: string) => {
    queryClient.setQueryData<string>(['current', 'subtab', 'sources'], subtab);
  };

  return { currentSourceSubTab, updateCurrentSourceSubTab };
};