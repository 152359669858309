import { CloseButton } from "../../../../ErrandsPage/ErrandsPage-styling";
import { PopupOverlay } from "../../../../ErrandsPage/ErrandsPage-styling";
import { AdminButton, AdminPopupInput, AdminPopupText, AdminPopupWindow, AdminSettingContainer, PopupFormButtonContainer, PopupFormInput, PopupFormLabel, PopupFormSubmitButton, PopupFormTextarea } from "../../../AdminPage-styling";
import { Template, templateSchema, TTemplateSchema } from "../../../../Types";
import { useForm } from "react-hook-form";
import { AuthorizationError } from "../../../../AuthorizationPages/Authorization-styling";
import { useTemplateMutation } from "../../../../Mutations/TemplateMutation";
import { zodResolver } from "@hookform/resolvers/zod";
import TextareaAutosize from "@mui/material/TextareaAutosize";

type EditTemplatePopupProps = {
  onClose: () => void;
  onSave: (template: Template) => void;
  selectedTemplate: Template;
};

const EditTemplatePopup = ({ onClose, onSave, selectedTemplate }: EditTemplatePopupProps) => {

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TTemplateSchema>({
    resolver: zodResolver(templateSchema),
    defaultValues: selectedTemplate,
  });

  const onSubmit = (data: TTemplateSchema) => {
    console.log(data);
    const template = {
      ...data
    } as Template;
    if (selectedTemplate.id) {
      template.id = selectedTemplate.id;
    }
    onSave(template);
  };

  return (
    <PopupOverlay>
      <AdminPopupWindow 
        style={{ width: '80%', maxWidth: '1000px' }}
      >
        <CloseButton onClick={onClose} />
        <h2>Edit Template</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AdminSettingContainer>
            <AdminPopupText>Title</AdminPopupText>
            <AdminPopupInput 
              {...register('title')}
              type="text"
              placeholder="Title"
            />
            {errors.title && <AuthorizationError>{errors.title.message}</AuthorizationError>}
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminPopupText>Text</AdminPopupText>
            <TextareaAutosize 
              {...register('text')}
              placeholder="Text"
              minRows={8}
              style={{ 
                padding: '8px 8px 0px 8px',
                border: '1px solid #333', 
                borderRadius: '5px',
                resize: 'none',
                maxHeight: '60vh',
              }}
            />
            {errors.text && <AuthorizationError>{errors.text.message}</AuthorizationError>}
          </AdminSettingContainer>
          <AdminButton type="submit" disabled={isSubmitting}>Save</AdminButton>
        </form>
      </AdminPopupWindow>
    </PopupOverlay>
  );
};

export default EditTemplatePopup;