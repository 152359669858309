import {
  ColumnDef,
  RowData,
} from '@tanstack/react-table'
import './Tables.css'
import { useMemo, useState } from 'react'
import { UserUnitAccess } from '../../Types'
import { AdminButton, AdminCategoryTitle, DeleteButton } from '../AdminPage-styling'
import { FaTrashCan } from 'react-icons/fa6'
import Swal from 'sweetalert2'
import { useUserUnitAccesses } from '../../Queries/useUserUnitAccesses'
import { useUserMeta } from '../../Queries/useUserMeta'
import { useUserUnitAccessMutation } from '../../Mutations/UserUnitAccessMutation'
import { useRoleList } from '../../Queries/useRoleList'
import { ax } from '../../Utils'
import Table from './Table'
import AddUserPopup from './AddUserPopup'
import { useQueryClient } from '@tanstack/react-query'
import { useCurrentUser } from '../../Queries/useCurrentUser'
import { Checkbox } from '@mantine/core'

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select'
  }
}

export function UsersTable() {

  const [showAddUser, setShowAddUser] = useState(false);

  const queryClient = useQueryClient();

  const { data: currentUser } = useCurrentUser();
  const { data: userMeta } = useUserMeta();
  const { data: userUnitAccesses} = useUserUnitAccesses(userMeta?.unit_id);
  const { data: roleList } = useRoleList();
  const userUnitAccessMutation = useUserUnitAccessMutation();


  const handleRoleChange = (row: UserUnitAccess, roleKey: keyof UserUnitAccess['roles']) => {
    const requestBody = {
      user_id: row.user_id,
      unit_id: row.unit_id,
      role_id: roleList?.find(role => role.name === roleKey)?.id,
      role_name: roleKey,
      obj_id: '',
    }
    if (!row.roles[roleKey]) { // Adding a new role
      userUnitAccessMutation.mutate(requestBody, {
        onSuccess: (response, variables) => {
          if (variables.user_id === currentUser?.id) {
            queryClient.setQueryData(['user_unit_access', 'all', 'current_user', { unit_id: variables.unit_id }], (oldData: UserUnitAccess) => {
              return {
                ...oldData,
                roles: {
                  ...oldData.roles,
                  [roleKey]: {
                    id: response.data.id,
                    role_id: response.data.role_id,
                    role_name: roleKey,
                  }
                }
              }
            });
          }
        }
      });
    } else { // Removing a role
      requestBody.obj_id = row.roles[roleKey].id;
      userUnitAccessMutation.mutate(requestBody);
    }
  }

  const handleDeleteUserPopup = (userId: string, userEmail: string, unitName: string, unitId: string) => {
    Swal.fire({
      title: `Är du säker på att du vill ta bort användaren?`,
      text: `Användaren ${userEmail} kommer förlora all åtkomst till ${unitName}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort användare",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId, unitId);
      }
    });
  };

  const deleteUser = (userId: string, unitId: string) => {
    ax.delete(`/user_unit_access/all/unit/?user_id=${userId}&unit_id=${unitId}`);
    queryClient.setQueryData(['user_unit_access', 'all', 'list', {unit_id: unitId}], (oldData: UserUnitAccess[]) => {
      return oldData.filter((uua) => uua.user_id !== userId);
    });
    queryClient.invalidateQueries({ queryKey: ['users', 'all', 'exclude_current_unit', {unit_id: unitId}] });
  }

  const columns = useMemo<ColumnDef<UserUnitAccess, any>[]>(
    () => [
      {
        accessorKey: 'user_email',
        cell: info => {
          return (
            <>
              {info.getValue()}
            </>
          )
        },
        header: () => <span>Email</span>,
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorFn: row => Boolean(row.roles.reader),
        id: 'reader',
        cell: info => (
          <Checkbox
            disabled={true}
            checked={Boolean(info.getValue())}
            onChange={() => handleRoleChange(info.row.original, 'reader')}
          />
        ),
        header: () => <span>Reader</span>,
        meta: {
          filterVariant: 'select',
        },
      },
      {
        accessorFn: row => Boolean(row.roles.feedback),
        id: 'feedback',
        cell: info => {
          const isCurrentUser = info.row.original.user_id === currentUser?.id;
          if (isCurrentUser && info.getValue()) {
            return (
              <Checkbox
                checked={Boolean(info.getValue())}
                disabled={true}
                title="Du kan inte ta bort din egen roll"
              />
            )
          }
          return (
            <Checkbox
              checked={Boolean(info.getValue())}
              onChange={() => handleRoleChange(info.row.original, 'feedback')}
            />
          )
        },
        header: () => <span>Feedback</span>,
        meta: {
          filterVariant: 'select',
        },
      },
      {
        accessorFn: row => Boolean(row.roles.marketing),
        id: 'marketing',
        cell: info => {
          const isCurrentUser = info.row.original.user_id === currentUser?.id;
          if (isCurrentUser && info.getValue()) {
            return (
              <Checkbox
                checked={Boolean(info.getValue())}
                disabled={true}
                title="Du kan inte ta bort din egen roll"
              />
            )
          }
          return (
            <Checkbox
              checked={Boolean(info.getValue())}
              onChange={() => handleRoleChange(info.row.original, 'marketing')}
            />
          )
        },
        header: () => <span>Marknadsföring</span>,
        meta: {
          filterVariant: 'select',
        },
      },
      {
        accessorFn: row => Boolean(row.roles.admin),
        id: 'admin',
        cell: info => {
          const isCurrentUser = info.row.original.user_id === currentUser?.id;
          if (isCurrentUser && info.getValue()) {
            return (
              <Checkbox
                checked={Boolean(info.getValue())}
                disabled={true}
                title="Du kan inte ta bort din egen roll"
              />
            )
          }
          return (
            <Checkbox
              checked={Boolean(info.getValue())}
              onChange={() => handleRoleChange(info.row.original, 'admin')}
            />
          )
        },
        header: () => <span>Admin</span>,
        meta: {
          filterVariant: 'select',
        },
      },
      {
        accessorKey: 'delete',
        enableSorting: false,
        cell: info => {
          const isCurrentUser = info.row.original.user_id === currentUser?.id;
          if (isCurrentUser) {
            return null;
          }
          return (
            <DeleteButton onClick={() => handleDeleteUserPopup(info.row.original.user_id, info.row.original.user_email, info.row.original.unit_name, info.row.original.unit_id)}>
              <FaTrashCan />
            </DeleteButton>
          )
        },
        header: () => <></>,
        meta: {
          filterVariant: undefined,
        },
      }
      // {
      //   accessorKey: 'age',
      //   header: () => 'Age',
      //   meta: {
      //     filterVariant: 'range',
      //   },
      // },
      // {
      //   accessorKey: 'visits',
      //   header: () => <span>Visits</span>,
      //   meta: {
      //     filterVariant: 'range',
      //   },
      // },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   meta: {
      //     filterVariant: 'select',
      //   },
      // },
      // {
      //   accessorKey: 'progress',
      //   header: 'Profile Progress',
      //   meta: {
      //     filterVariant: 'range',
      //   },
      // },
    ],
    [handleRoleChange]
  )

  //const [data, setData] = useState<UserUnitAccess[]>(() => makeData(5_000))

  const closeAddUserPopup = () => {
    setShowAddUser(false);
  }

  return (
    <>
      <AdminCategoryTitle>Användare på {userMeta?.unit_name}</AdminCategoryTitle>
      <Table 
        columns={columns} 
        data={userUnitAccesses ?? []} 
        columnWidths={{
          first: '40%',
          last: '1%',
          middle: '10%',
        }}
      />
      <AdminButton onClick={() => setShowAddUser(true)}>Lägg till användare</AdminButton>
      {showAddUser && 
        <AddUserPopup closePopup={closeAddUserPopup} />
      }
      {/* <AdminFormTitle>Lägg till användare</AdminFormTitle>
      <AddUser /> */}
    </>
  )
}