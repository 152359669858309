import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaCog, FaTimes, FaUserCog } from 'react-icons/fa';
import { FaBuilding, FaChevronDown, FaChevronUp, FaLanguage } from 'react-icons/fa6';
import { ImExit } from 'react-icons/im';
import './HamburgerMenu-styling.css';
import { ax } from '../Utils';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useUsersAccessibleUnits } from '../Queries/useUsersAccessibleUnits';
import { useChangeUnitMutation } from '../Mutations/ChangeUnitMutation';
import Swal from 'sweetalert2';
import { useUserMeta } from '../Queries/useUserMeta';
import { Button } from '@mantine/core';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showUnitDropdown, setShowUnitDropdown] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: userMeta } = useUserMeta();
  const { data: accessibleUnits, isLoading } = useUsersAccessibleUnits();

  const changeUnitMutation = useChangeUnitMutation();
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && buttonRef.current && menuRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      menuRef.current.style.top = `${buttonRect.bottom}px`; // 8px space below the button
      menuRef.current.style.right = `${window.innerWidth - buttonRect.right}px`;
    }
  }, [isOpen]);

  const handleSignOut = async () => {
    try {
      await ax.post('/auth/logout');
      queryClient.clear();
      // window.location.href = 'https://ragge.ai/';
      navigate('/login');
    } catch (error) {
    }
  };

  const handleSwitchUnit = (unitId: string) => {
    if (unitId !== userMeta?.unit_id) {
      try {
        changeUnitMutation.mutate(unitId, {
          onSuccess: () => {
            if (window.location.pathname === '/no-access') {
              navigate('/user');
            }
          }
        });
      } catch (error) {
        Swal.fire({
          title: 'Misslyckades med att byta enhet',
          text: 'Kontrollera att du har tillgång till den nya enheten och försök igen.',
          icon: 'error',
        });
      }
    }
  }
  return (
    <div className="hamburger-menu">
      <Button
        variant="subtle"
        color="gray.0"
        size="md"
        p={4}
        mt={4}
        mr={4}
        ref={buttonRef}
        onClick={toggleMenu}
        className="hamburger-icon"
        aria-label="Toggle Menu"
      >
        {isOpen ? <FaTimes size={36} /> : <FaBars size={36} />}
      </Button>
      <div ref={menuRef} className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
        {/* <button className="menu-item">
          <FaUserCog className="menu-icon" />Mina sidor
        </button>
        <button className="menu-item">
          <FaLanguage className="menu-icon" />Språk
        </button> */}
        <button className='menu-item' 
          onClick={() => setShowUnitDropdown(!showUnitDropdown)}
        >
          <FaBuilding className="menu-icon" />Byt enhet
          {showUnitDropdown ? <FaChevronUp style={{ marginLeft: 'auto' }} /> : <FaChevronDown style={{ marginLeft: 'auto' }} />}
        </button>
        {showUnitDropdown && (
          <div className="unit-dropdown">
            {isLoading ? (
              <div className="unit-option">Laddar...</div>
            ) : Array.isArray(accessibleUnits) && accessibleUnits[0]?.units ? (
              accessibleUnits[0].units.map((unit) => (
                <button
                  key={unit.unit_id}
                  className={`unit-option ${unit.unit_id === userMeta?.unit_id ? 'unit-option-active' : ''}`}
                  onClick={() => {
                    if (unit.unit_id !== userMeta?.unit_id) {
                      handleSwitchUnit(unit.unit_id);
                    }
                  }}
                >
                  {unit.unit_name}
                </button>
              ))
            ) : (
              <div>Inga enheter tillgängliga</div>
            )}
          </div>
        )}
        <button className="menu-item" onClick={handleSignOut}>
          <ImExit className="menu-icon" />Logga ut
        </button>
      </div>
    </div>
  );
};

export default HamburgerMenu;
