import styled from 'styled-components';

//Filter Section
export const FilterSection = styled.div`
  width: 30%;
  height: calc(100% - 33px);
  min-width: 150px;
  margin-top: 25px;
  overflow: auto;
  /* margin-bottom: 10px; */
  border-radius: 6px 0 0 6px;
  border: solid #333;
  border-width: 4px 2px 4px 4px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h1`
  font-size: 36px;
  color: #fff;
  font-weight: 500;
  padding-left: 10px;
  margin-top: 10px;
`;

export const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
`;

export const SearchBar = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  height: 35px;
  width: 100%;
  padding: 2px 20px 2px 32px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);

  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

export const SearchIconButton = styled.button`
  position: absolute;
  top: 6px;
  left: 12px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
  color: #1a1a1a;
  
  &:hover {
    top: 5px;
    left: 11px;
    font-size: 1.5rem;
  }
`;

export const FilterContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
`;

export const FilterText = styled.a`
  font-size: 20px;
  color: #fff;
  text-align: start;
  padding-top: 5px;
`;

export const FilterBox = styled.select`
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  height: 35px;
  width: 67%;
  padding: 2px 20px 2px 4px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;//  box-shadow: 0px 4px 7px rgba(70, 70, 70);

export const FilterTime = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  height: 35px;
  width: 67%;
  padding: 2px 20px 2px px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;



//Results Section
export const ResultsSection = styled.div`
  width: 30%;
  height: calc(100% - 33px);
  min-width: 150px;
  margin-top: 25px;
  overflow: auto;
  /* margin-bottom: 10px; */
  border: solid #333;
  border-width: 4px 2px 4px 2px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`;

export const ResultsListContainer = styled.div`
  width: 100%;
  align-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  overflow-y: auto;
`;

export const ResultItemStyling = styled.div<{ selected: boolean }>`
  cursor: pointer;
  border-radius: 6px;
  background-color: ${(props) => (props.selected ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.3)')};
  margin-bottom: 2px;
  overflow: clip;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const ResultTitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ResultItemTitle = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;

export const FavouriteStar = styled.img`
  width: 20px;
  height: 19px;
  cursor: default;

  &:hover {
    width: 23px;
    height: 22px;
  }
`;

export const ResultItemText = styled.div`
  max-height: 2.6rem;
  font-size: 14px;
  color: #fff;
  overflow: clip;
`;

//Display Section
export const DisplaySection = styled.div`
  width: 40%;
  height: calc(100% - 33px);
  min-width: 150px;
  margin-top: 25px;
  overflow: auto;
  /* margin-bottom: 10px; */
  border-radius: 0 6px 6px 0;
  border: solid #333;
  border-width: 4px 4px 4px 2px;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const DisplayChatText = styled.p`
  color: #fff;
  line-height: 1.25;
  white-space: pre-line;
  overflow-wrap: break-word;
  padding-top: 8px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 11px;
`;

export const ChatItemText = styled.div`
  padding-top: 8px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 11px;
  color: #fff;
  white-space: pre-line;
`;

// export const CustomObjectBubble = styled.div`
//   background-color: rgba(0, 0, 0, 0.2);
//   margin: 0 10px;
//   max-width: 100%;
//   padding: 5px 5px 10px 5px;
//   border-radius: 10px;
// `;