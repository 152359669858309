import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Feedback } from "../Types";

export const useFeedbackList = (source_type: string, search_text: string, offset?: number) => {
  return useQuery<{data: Feedback[], totalCount: number}, Error>({
    queryKey: ["feedback", "all", "list", source_type, search_text, offset],
    queryFn: (): Promise<{data: Feedback[], totalCount: number}> => 
      ax.get(`/feedback/all/list/?search_text=${search_text}&source_type=${source_type}&offset=${offset}`)
        .then((res) => {
          console.log(res.headers['x-total-count'])
          return {data: res.data, totalCount: res.headers['x-total-count']}
        }),
    refetchOnReconnect: "always",
    refetchInterval: 1000 * 30, //hur ofta behöver feedbacklistan uppdateras?
  });
};