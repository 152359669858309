import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import {
  MainContent,
  CopilotSection,
  ConversationSection,
  CopilotContainer,
  CopilotChatItem,
  ChatProfileImgContainer,
  CopilotProfileImg,
  CopilotChatItemText,
  CopilotInputSection,
  CopilotInput,
  CopilotImgAnimation,
  RefreshCopilotButton,
  SendIconButton,
  InfoIcon,
} from "./UserPage-styling";
import RefreshCopilotPopUp from './RefreshCopilotPopUp';
import MarkdownRenderer from '../Components/MarkdownRenderer';
import { useCurrentUser } from '../Queries/useCurrentUser';
import { useAssistantConfigForModule } from '../Queries/useAssistantConfigForModule';
import { useAssistantChat } from '../Queries/useAssistantChat';
import { useAnswerLoading } from '../Queries/useAnswerLoading';
import { FaPaperPlane } from 'react-icons/fa6';
import { StandardBase } from '../Components/StandardBase';
import { useAssistantMessageReferences } from '../Queries/useAssistantMessageReferences';
import { FaInfoCircle, FaSyncAlt } from 'react-icons/fa';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import ReferencePopup from '../Components/ReferencePopup';
import { Button, Group, Stack, Textarea, Text } from '@mantine/core';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const UserPage = () => {

  const module = 'internal_chatbot';

  const conversationRef = useRef<HTMLDivElement>(null);
  const [showRefreshPopUp, setShowRefreshPopUp] = useState(false);
  const [refreshTriggered, setRefreshTriggered] = useState(false);
  const [showReferencePopup, setShowReferencePopup] = useState(false);
  const [selectedReferenceIndex, setSelectedReferenceIndex] = useState<number | null>(null);
  const hasStreamedWelcomeMessage = useRef(false);

  const { data: currentUser } = useCurrentUser();
  const { data: assistantConfig, ...assistantConfigQuery } = useAssistantConfigForModule(module);
  const {assistantChat, generateAnswer, streamWelcomeMessage, addMessage, clearChat, messageGroupIds, retryLastRequest} = useAssistantChat(module, undefined);
  const { data: answerLoading } = useAnswerLoading(module, undefined);
  const messageReferences = useAssistantMessageReferences(messageGroupIds);


  useEffect(() => {
    if (assistantChat.length === 1 && assistantChat[0] === '' && !hasStreamedWelcomeMessage.current) {
      hasStreamedWelcomeMessage.current = true;
      streamWelcomeMessage(undefined);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
    if (assistantChat[0] === "" && refreshTriggered) {
      setRefreshTriggered(false);
      streamWelcomeMessage(undefined);
    }
  }, [assistantChat]);

  const handleSendMessage = (text: string) => {
    addMessage(text);
    generateAnswer({text, module_name: module});
  };

  const toggleShowRefreshPopUp = () => {
    setShowRefreshPopUp(!showRefreshPopUp);
  };

  const handleRefreshCopilot = () => {
    setRefreshTriggered(true);
    clearChat();
    setShowRefreshPopUp(false);
  };

  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleInfoIconClick = (index: number) => {
    setSelectedReferenceIndex(index);
    setShowReferencePopup(true);
  };

  return (
    <StandardBase>
      <MainContent>
        <Stack gap="xs" h="100%" w="100%">
          <Group justify="flex-end">
            <Button onClick={toggleShowRefreshPopUp} mr={15}>
              <FaSyncAlt size={18}/>
            </Button>
          </Group>
          <CopilotContainer>
            <CopilotSection>
              <ConversationSection ref={conversationRef}>
                {assistantChat.map((item, index) => {
                  if (index % 2 === 0) { // Check if index is even
                    return (
                      <CopilotChatItem key={index} $isCopilot={true} >
                        {answerLoading[index/2] &&
                          <CopilotImgAnimation></CopilotImgAnimation>
                        }
                          <ChatProfileImgContainer>
                            {assistantConfig?.logo_url ? (
                              <CopilotProfileImg src={assistantConfig.logo_url} alt="AI"/>
                            ) : (
                              "AI"
                            )}
                          </ChatProfileImgContainer>
                          {answerLoading[index/2] ?
                            <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={18} />                        
                          : !item ?
                            <Group gap={0}>
                              <Text size="md">Någonting gick fel.</Text>
                              <Button
                                variant="subtle"
                                onClick={() => retryLastRequest()}
                                size="md"
                                p={5}
                              >
                                Klicka här för att försöka igen
                              </Button>
                            </Group>
                            :
                            <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={18} />
                          }
                          {index > 0 && messageReferences[(index/2)-1] && messageReferences[(index/2)-1].length > 0 && !answerLoading[index/2] ?
                            <LightTooltip 
                            title={`Se ${messageReferences[(index/2)-1].length} källor som hjälpte till att svara på din fråga`}
                            >
                              <InfoIcon onClick={() => {
                                handleInfoIconClick((index/2)-1);
                              }}>
                                <FaInfoCircle />
                              </InfoIcon>
                            </LightTooltip>
                            // : index !== 0 && messageReferences[(index/2)-1] && !answerLoading[index/2] ?
                            // <LightTooltip 
                            //   title={`Inga relevanta källor tillgängliga`}
                            // >
                            //   <InfoIcon>
                            //     <FaInfoCircle />
                            //   </InfoIcon>
                            // </LightTooltip>
                            :
                            null
                          }

                      </CopilotChatItem>
                    )
                  } else {
                    return (
                      <CopilotChatItem key={index} $isCopilot={false} >
                        <ChatProfileImgContainer>
                          {currentUser?.first_name.charAt(0)}
                        </ChatProfileImgContainer>
                        <CopilotChatItemText>{item}</CopilotChatItemText>
                      </CopilotChatItem>
                    )
                  }
                })}

              </ConversationSection>
              <CopilotInputComponent onSend={handleSendMessage} />

            </CopilotSection>
          </CopilotContainer>
        </Stack>
      </MainContent>
      {showRefreshPopUp &&
        <RefreshCopilotPopUp
          toggleShowRefreshPopUp={toggleShowRefreshPopUp}
          handleRefreshCopilot={handleRefreshCopilot}
        />
      }
      {showReferencePopup &&
        <ReferencePopup
          references={messageReferences[selectedReferenceIndex!]}
          onClose={() => setShowReferencePopup(false)}
        />
      }
    </StandardBase>
  );
};

export default UserPage;

const CopilotInputComponent = React.memo(({ onSend }: { onSend: (text: string) => void }) => {
  const [inputText, setInputText] = useState('');

  const handleTextareaChange = (e: { target: { value: string; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };

  const handleSend = () => {
    if (inputText === '') {
      return;
    }
    onSend(inputText);
    setInputText('');
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <CopilotInputSection>
      {/* <Button variant="subtle" color="black" onClick={handleSend}>
        <FaPaperPlane size={20}/>
      </Button> */}
      <Textarea
        // id='copilotInputId'
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Skriv din fråga här..."
        autosize
        minRows={1}
        maxRows={5}
        onKeyDown={handleEnterSend}
        w="100%"
        radius="md"
        styles={{
          input: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            color: "#fff",
            fontSize: "1rem",
            // fontFamily: "Tahoma, Arial, Arabic Transparent",
            lineHeight: "1.25",
            padding: "10px",
            paddingRight: "60px",
            borderColor: "rgba(0, 0, 0, 0.4)",
          }
        }}
        rightSectionWidth={60}
        rightSection={
          <Button variant="subtle" color="black" onClick={handleSend}>
            <FaPaperPlane size={22}/>
          </Button>
        }
      />
    </CopilotInputSection>
  );
});