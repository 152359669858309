import { TAddInboxSchema, TInboxConfigSchema, TInboxLabelsSchema, addInboxSchema, inboxLabelsSchema, inboxConfigSchema } from "../../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ax } from "../../../Utils";
import { AdminButton, AdminInputError, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingText } from "../../AdminPage-styling";
import Swal from "sweetalert2";
import { Modal, Stepper, Button, Group, MultiSelect, Checkbox, Input, Text, Stack, Loader } from '@mantine/core';
import { useState, useEffect, useRef } from 'react';
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import '@mantine/core/styles.css';
import { Link } from "react-router-dom";
import { queryClient } from "../../../lib/queryClient";
import { GoogleButton, MicrosoftButton } from "../../../Components/AuthButtons";

export default function AddInbox() {
  const [active, setActive] = useState(0);
  const [emailLabels, setEmailLabels] = useState<string[]>([]);
  const [createDrafts, setCreateDrafts] = useState(false);
  const [authUrl, setAuthUrl] = useState('');
  const [emailSourceId, setEmailSourceId] = useState('');

  const [opened, { open, close }] = useDisclosure(false);
  const openedRef = useRef(false); // Create a ref to track the current opened state
  const isMobile = useMediaQuery('(max-width: 50em)');
  
  // Update the ref whenever the opened state changes
  useEffect(() => {
    openedRef.current = opened;
  }, [opened]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<TAddInboxSchema>({
    resolver: zodResolver(addInboxSchema),
  });

  const {
    register: registerLabels,
    handleSubmit: handleSubmitLabels,
    formState: { errors: errorsLabels, isSubmitting: isSubmittingLabels },
    reset: resetLabels,
    watch: watchLabels,
    setValue: setLabelsValue,
    control: labelsControl,
  } = useForm<TInboxLabelsSchema>({
    resolver: zodResolver(inboxLabelsSchema),
    defaultValues: {
      labels: [],
    }
  });

  const {
    register: registerConfig,
    handleSubmit: handleSubmitConfig,
    formState: { errors: errorsConfig, isSubmitting: isSubmittingConfig },
    reset: resetConfig,
    watch: watchConfig,
  } = useForm<TInboxConfigSchema>({
    resolver: zodResolver(inboxConfigSchema),
    defaultValues: {
      create_drafts_in_source: false,
    },
  });

  const handleFirstStep = async (data: TAddInboxSchema) => {
    try {
      const response = await ax.post(`/email_source/oauth`, {
        name: data.inboxName,
        email_address: data.email,
        provider: 'gmail',
      });
      setEmailSourceId(response.data.id);
      
      if (response.data) {
        const authUrlResponse = await ax.get(`/email_source/oauth/auth_url?email_source_id=${response.data.id}`);
        setAuthUrl(authUrlResponse.data);
        window.open(authUrlResponse.data, '_blank');
        setActive(1);

        // Poll for authentication status
        for (let i = 0; i < 120; i++) {  // 10 minute timeout
          // Check if modal is still open using the ref
          if (!openedRef.current) {
            console.log('Modal closed, stopping polling');
            return;
          }
          
          const authStatus = await ax.get(`/email_source/oauth?obj_id=${response.data.id}`);
          console.log('authStatus', authStatus.data.is_authenticated);
          
          if (authStatus.data.is_authenticated) {
            setEmailLabels(authStatus.data.all_folders);
            setActive(2);
            return;
          }
          
          await new Promise(resolve => setTimeout(resolve, 5000));
        }
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Inkorgen finns redan.',
          text: 'Hitta inkorgen i listan för att se status och konfigurera den.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel!',
          text: 'Försök igen, eller kontakta support om problemet kvarstår.',
        });
      }
    }
  };

  const handleFirstStepMicrosoft = async (data: TAddInboxSchema) => {
    try {
      const response = await ax.post(`/email_source/oauth`, {
        name: data.inboxName,
        email_address: data.email,
        provider: 'outlook',
      });
      setEmailSourceId(response.data.id);
      
      if (response.data) {
        const authUrlResponse = await ax.get(`/email_source/oauth/auth_url?email_source_id=${response.data.id}`);
        setAuthUrl(authUrlResponse.data);
        window.open(authUrlResponse.data, '_blank');
        setActive(1);

        // Poll for authentication status
        for (let i = 0; i < 120; i++) {  // 10 minute timeout
          // Check if modal is still open using the ref
          if (!openedRef.current) {
            console.log('Modal closed, stopping polling');
            return;
          }
          
          const authStatus = await ax.get(`/email_source/oauth?obj_id=${response.data.id}`);
          if (authStatus.data.is_authenticated) {
            setEmailLabels(authStatus.data.all_folders);
            setActive(2);
            return;
          }
          
          await new Promise(resolve => setTimeout(resolve, 5000));
        }
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Inkorgen finns redan.',
          text: 'Hitta inkorgen i listan för att se status och konfigurera den.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel!',
          text: 'Försök igen, eller kontakta support om problemet kvarstår.',
        });
      }
    }
  };

  const handleSelectLabels = async (data: TInboxLabelsSchema) => {
    console.log(data);
    try {
      await ax.post(`/email_source/selected_folders?email_source_id=${emailSourceId}`, data.labels);
      setActive(3);
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Något gick fel!',
        text: 'Försök igen, eller kontakta support om problemet kvarstår.',
      });
    }
  }

  const handleFinalSubmit = async (data: TInboxConfigSchema) => {
    try {
      await ax.patch(`/email_source`, {
        id: emailSourceId,
        sync_from: data.sync_from_date,
        create_drafts_in_source: data.create_drafts_in_source,
        is_active: true,
      });
      
      queryClient.invalidateQueries({ queryKey: ['email_source', 'all'] });

      close();
      reset();
      setActive(0);

    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Något gick fel!',
        text: 'Försök igen, eller kontakta support om problemet kvarstår.',
      });
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Lägg till e-postinkorg"
        size="xl"
        fullScreen={isMobile}
        centered
        styles={{
          title: {
            fontSize: '1.5rem',
          },
          header: {
            marginBottom: '0',
          },
        }}
      >
        <Stepper active={active} size="xs">
          <Stepper.Step label="Steg 1" description="E-post information">
            <form onSubmit={handleSubmit(handleFirstStep)} style={{ marginTop: '20px' }}>
              <Stack>
                <Stack gap="1">
                  <Text>E-postadress</Text>
                  <Input
                    {...register("email")}
                    type="email"
                    placeholder="exempel@domän.se"
                  />
                  {errors.email && (
                    <AdminInputError>{`${errors.email.message}`}</AdminInputError>
                  )}
                </Stack>
                <Stack gap="1">
                  <Text>Namnge inkorg</Text>
                  <Input
                    {...register("inboxName")}
                    type="text"
                    placeholder="Namn"
                  />
                  {errors.inboxName && (
                    <AdminInputError>{`${errors.inboxName.message}`}</AdminInputError>
                  )}
                  <Group justify="flex-end" mt="xl">
                    <Stack gap= "xs">
                      <GoogleButton
                        onClick={handleSubmit(handleFirstStep)}
                        text="Fortsätt med Google"
                      />
                      
                      <MicrosoftButton
                        onClick={handleSubmit(handleFirstStepMicrosoft)}
                        text="Fortsätt med Microsoft"
                      />
                    </Stack>
                  </Group>
                </Stack>
              </Stack>
            </form>
          </Stepper.Step>

          <Stepper.Step label="Steg 2" description="Autentisering">
            <Stack justify="center" align="center" mt="xl">
              <Text>Vänligen autentisera din e-post i det nya fönstret</Text>
              <Text>
                <Link to={authUrl} target="_blank">
                  Klicka här om inget nytt fönster öppnats
                </Link>
              </Text>
              <Loader color="#0f86a1" />
              <Text>Inväntar autentisering...</Text>
            </Stack>
            <Group justify="flex-start" mt="xl">
              <Button variant="outline" color="gray.7" onClick={() => setActive(0)}>Tillbaka</Button>
            </Group>
          </Stepper.Step>

          <Stepper.Step label="Steg 3" description="Välj mappar">
            <form onSubmit={handleSubmitLabels(handleSelectLabels)} style={{ marginTop: '20px' }}>
              <Stack gap="10">
                <Text>Här kan du välja vilka mappar du vill importera. E-posthistoriken i valda mappar kommer att synkroniseras med vår databas så
                  att dessa kan besvaras och användas av din AI-assistent.</Text>
                <MultiSelect
                  data={emailLabels}
                  value={watchLabels("labels")}
                  onChange={(value) => setLabelsValue("labels", value)}
                  placeholder="Välj mappar"
                  searchable
                  styles={{
                    input: {
                      borderColor: '#555',
                    },
                  }}
                />
              </Stack>
              <Group justify="flex-end" mt="xl">
                <Button color="#0f86a1" type="submit">Nästa</Button>
              </Group>
            </form>
          </Stepper.Step>

          <Stepper.Step label="Steg 4" description="Konfigurera">
            <form onSubmit={handleSubmitConfig(handleFinalSubmit)} style={{ marginTop: '20px' }}>
              <Stack gap="30" mb="lg">
                <Stack gap="1">
                  <Text>Välj ett datum för att hämta e-postmeddelanden i valda mappar mottagna efter detta datum</Text>
                  <Input
                    type="date"
                    {...registerConfig("sync_from_date")}
                  />
                  {errorsConfig.sync_from_date && (
                    <AdminInputError>{`${errorsConfig.sync_from_date.message}`}</AdminInputError>
                  )}
                </Stack>
                <Checkbox
                  label="Skapa utkast i e-postprogrammet"
                  {...registerConfig("create_drafts_in_source")}
                />
              </Stack>
              <Group justify="space-between" mt="xl">
                <Button 
                  type="button" 
                  variant="outline" 
                  color="gray.7" 
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(2);
                  }}
                >Tillbaka</Button>
                <Button color="#0f86a1" type="submit">Slutför</Button>
              </Group>
            </form>
          </Stepper.Step>
        </Stepper>
      </Modal>

      <AdminButton onClick={open}>
        Lägg till ny inkorg
      </AdminButton>
    </>
  );
}