import { Text, Stack } from "@mantine/core";
import { StandardBase } from "../Components/StandardBase";
import { MainContent } from "../LoginPage/LoginPage-styling";


const SomethingWentWrong = () => {
  return (
    <StandardBase>
      <MainContent>
        <Stack>
          <Text c="white" fz="28">
            Något gick fel!
          </Text>
          <Text c="white" size="xl">
            Vänligen gå tillbaka till fliken du var på och försök igen.
          </Text>
        </Stack>
      </MainContent>
    </StandardBase>
  );
};

export default SomethingWentWrong;