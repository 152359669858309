// import React, { useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
import { AdminButton, AdminCategoryTitle } from '../../../AdminPage-styling';
import { ax } from '../../../../Utils';
import Table from '../../../AdminComponents/Table';
import { toast } from 'react-toastify';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import Swal from 'sweetalert2';
import { useFiles } from '../../../../Queries/useFiles';
import { FileData } from '../../../../Types';
import { queryClient } from '../../../../lib/queryClient';
import { memo, useState, useEffect, useCallback } from 'react';
import { Checkbox } from '@mantine/core';

// interface FileData {
//   id: string;
//   title: string;
//   description: string;
//   can_be_used_by_external_chatbot: boolean;
//   file_type: string;
//   file_name: string;
// }

const EditableCell = memo(({ getValue, row, field, handleUpdate }: {
  getValue: () => string;
  row: any;
  field: keyof FileData;
  handleUpdate: (fileId: string, updatedData: Partial<FileData>) => void;
}) => {
  const [localText, setLocalText] = useState(getValue() ?? '');

  useEffect(() => {
    setLocalText(getValue() ?? '');
  }, [getValue]);

  const handleBlur = useCallback((e: { target: { value: string } }) => {
    if (e.target.value !== row.original[field] && (e.target.value || row.original[field])) {
      handleUpdate(row.original.id, { [field]: e.target.value });
    }
  }, [row.original, field, handleUpdate]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalText(e.target.value);
  }, []);

  return (
    <input
      className="table-input"
      value={localText}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ pointerEvents: 'none' }}
    />
  );
});

EditableCell.displayName = 'EditableCell';

const FilesTable = () => {
  // const [files, setFiles] = useState<FileData[]>([]);
  // const { register, handleSubmit, reset } = useForm();

  const { data: files, isLoading, error } = useFiles();

  // useEffect(() => {
  //   fetchFiles();
  // }, []);

  // const fetchFiles = async () => {
  //   try {
  //     const response = await ax.get('/file/all/');
  //     setFiles(response.data);
  //   } catch (error) {
  //     toast.error('Failed to fetch files.');
  //   }
  // };

  // const onSubmit = async (data: any) => {
  //   try {
  //     const createResponse = await ax.post('/file', { title: data.title, description: data.description });
  //     const fileId = createResponse.data.id;
  //     const formData = new FormData();
  //     formData.append('file_upload', data.file[0]);
  //     await ax.post(`/file/upload_file/?file_id=${fileId}`, formData);
  //     toast.success('File uploaded successfully!');
  //     fetchFiles();
  //     reset();
  //   } catch (error) {
  //     toast.error('Failed to upload file.');
  //   }
  // };

  const handleDownload = async (fileId: string, fileName: string) => {
    try {
      const response = await ax.get(`/file/download_file/?file_id=${fileId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error('Failed to download file.');
    }
  };

  const handleUpload = async (fileId: string, file: File) => {
    console.log('file', file);
    try {
      const formData = new FormData();
      formData.append('file_upload', file);
      await ax.post(`/file/upload_file/?file_id=${fileId}`, formData).then((res) => {
        toast.success('File uploaded successfully!');
        queryClient.setQueryData(['file', 'all'], (oldData: FileData[]) => {
          return oldData.map((f: FileData) => f.id === fileId ? res.data : f);
        });
      });
    } catch (error) {
      toast.error('Failed to re-upload file.');
    }
  };

  const handleUpdate = async (fileId: string, updatedData: Partial<FileData>) => {
    // optimistic update
    queryClient.setQueryData(['file', 'all'], (oldData: FileData[]) => {
      return oldData.map((f: FileData) => {
        if (f.id === fileId) {
          return { ...f, ...updatedData };
        }
        return f;
      });
    });
    try {
      await ax.patch('/file', { id: fileId, ...updatedData }).then((res) => {
        toast.success('File updated successfully!');
      });
    } catch (error) {
      // revert optimistic update
      queryClient.invalidateQueries({ queryKey: ['file', 'all'] });
      toast.error('Failed to update file.');
    }
  };

  const openUploadPopup = (fileId: string) => {
    if (fileId) {
      Swal.fire({
        title: 'Upload New File',
        input: 'file',
        inputAttributes: {
          accept: '*/*',
          'aria-label': 'Upload your file'
        },
        showCancelButton: true,
        confirmButtonText: 'Upload',
        preConfirm: (file) => {
          if (file) {
            handleUpload(fileId, file);
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Upload New File',
        input: 'file',
        inputAttributes: {
          accept: '*/*',
          'aria-label': 'Upload your file'
        },
        showCancelButton: true,
        confirmButtonText: 'Upload',
        preConfirm: (file) => {
          if (file) {
            ax.post('/file', { title: '', description: '' }).then((res) => {
              queryClient.setQueryData(['file', 'all'], (oldData: any) => {
                return [res.data, ...oldData];
              });
              handleUpload(res.data.id, file);
            });
          }
        }
      });
    }
  };

  const handleDelete = async (fileId: string) => {
    try {
      await ax.delete(`/file/${fileId}`);
      toast.success('File deleted successfully!');
      queryClient.setQueryData(['file', 'all'], (oldData: FileData[]) => {
        return oldData.filter((f: FileData) => f.id !== fileId);
      });
    } catch (error) {
      toast.error('Failed to delete file.');
    }
  };

  const columns: ColumnDef<FileData>[] = [
    {
      header: 'Title',
      accessorFn: (row) => row.title,
      cell: ({ getValue, row }: CellContext<FileData, unknown>) => (
        <EditableCell
          getValue={() => getValue() as string}
          row={row}
          field="title"
          handleUpdate={handleUpdate}
        />
      ),
    },
    {
      header: 'Description',
      accessorFn: (row) => row.description,
      cell: ({ getValue, row }: CellContext<FileData, unknown>) => (
        <EditableCell
          getValue={() => getValue() as string}
          row={row}
          field="description"
          handleUpdate={handleUpdate}
        />
      ),
    },
    {
      header: 'Filename',
      accessorFn: (row) => row.file_name,
    },
    {
      header: 'External Chatbot',
      accessorFn: (row) => row.can_be_used_by_external_chatbot,
      cell: ({ getValue, row }: CellContext<FileData, unknown>) => (
        <Checkbox
          checked={getValue() as boolean || false}
          onChange={(e) => handleUpdate(row.original.id, { can_be_used_by_external_chatbot: e.target.checked })}
        />
      ),
    },
    {
      header: 'Actions',
      cell: ({ row }: { row: { original: FileData } }) => (
        <div>
          <AdminButton
            style={{ marginRight: '2px' }}
            onClick={() => handleDownload(row.original.id, row.original.file_name)}
            disabled={!row.original.file_name}
          >
            Download
          </AdminButton>
          <AdminButton
            style={{ marginRight: '2px' }}
            onClick={() => openUploadPopup(row.original.id)}
          >
            Upload New File
          </AdminButton>
          <AdminButton onClick={() => handleDelete(row.original.id)}>Delete</AdminButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <AdminCategoryTitle>Files</AdminCategoryTitle>
      <Table columns={columns} data={files || []} />
      <AdminButton onClick={() => openUploadPopup('')}>Upload File</AdminButton>
    </>
  );
};

export default FilesTable; 