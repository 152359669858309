import { useQuery } from '@tanstack/react-query';
import { ax } from '../Utils';

export const useTopics = () => {
  return useQuery({
    queryKey: ['topics'],
    queryFn: async () => {
      const response = await ax.get('/topic/all/list/');
      return response.data;
    }
  });
}; 