import React, { useState, useEffect, useRef } from 'react';
import {
    LoginBox,
    Title,
    Label,
    Input,
    Button,
    ForgotPasswordWrapper,
    ForgotPasswordText,
    LoginContent,
    MainContent,
} from "./LoginPage-styling";
import { Container, ContentContainer, Logo, LogoImg, Nav, NavBar, NavItem, PageBackground, ScreenProtector } from "../Styling/NavBar-styling"
import { ax } from "../Utils";
import { useNavigate } from 'react-router-dom';
import { User } from '../Types';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: #e74c3c;
  font-size: 14px;
  margin-top: 8px;
  padding: 10px;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 6px;
  text-align: center;
  animation: fadeIn 0.3s ease-in;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-5px); }
    to { opacity: 1; transform: translateY(0); }
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 13px;
  }
`;

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    //Focus the username input by default
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const checkBackendHealth = async () => {
      try {
        const response = await ax.get('/health');
        if (response.status !== 200) {
          setError('Tjänsten är för närvarande nere, försök igen senare.');
        }
      } catch (error) {
        console.error('Error during health check:', error);
        setError('Tjänsten är för närvarande nere, försök igen senare.');
      }
    };
    checkBackendHealth();
  }, []);

  const handleHome = async () => {
    navigate('/');
  };

  const handleForgotPassword = async () => {
    navigate('/login/forgot');
  };

  const handleLogin = async () => {
    try {
      const formData = new FormData();
      formData.set('username', username);
      formData.set('password', password);
      
      //This is just to see the contents of the FormData object
      const formDataArray: [string, FormDataEntryValue][] = Array.from(formData.entries());
      formDataArray.forEach(([key, value]) => {
      });
  
      // Send the login data to the backend using Axios
      const response = await ax.post(
        '/auth/login',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )

      // Check the response from the backend and handle accordingly
      if (response.status === 200 || response.status === 204) {
        navigate('/user');
      }

    } catch (error) {
      console.error('Error while trying to log in:', error);
      setError('Fel användarnamn eller lösenord! Försök igen.');
      // Handle any other errors that may occur during the login process.
    }
  };
  const handleUsernameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  };
  const handlePasswordKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            {/* <LogoImg onClick={handleHome} alt="Image"/> */}
            <Logo>ragge.ai</Logo>

          </NavBar>
          <ContentContainer>
            <MainContent>
              <LoginBox>
                <LoginContent>
                  <Title>Logga in</Title>
                  <div>
                    <Label>Email:</Label>
                    <Input type="text" value={username} 
                      onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                        setUsername(e.target.value);
                        if (e.target.value !== '') {
                          setError('');
                        }
                      }}
                      onKeyDown={handleUsernameKeyPress}
                      $error={!!error}
                      ref={usernameInputRef} />
                  </div>
                  <div>
                    <Label>Lösenord:</Label>
                    <Input type="password" value={password}
                      onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                        setPassword(e.target.value);
                        if (e.target.value !== '') {
                          setError('');
                        }
                      }}
                      onKeyDown={handlePasswordKeyPress}
                      $error={!!error}
                      ref={passwordInputRef}
                    />
                  </div>
                  <Button onClick={handleLogin}>Logga in</Button>
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </LoginContent>
                <ForgotPasswordWrapper>
                  <ForgotPasswordText onClick={handleForgotPassword}>Glömt lösenord?</ForgotPasswordText>
                </ForgotPasswordWrapper>
              </LoginBox>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default LoginPage;