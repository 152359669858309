import React, { useState, useEffect } from 'react';
import { ax } from '../../../../Utils';
import { toast } from 'react-toastify';
import { AdminButton, AdminPopupWindow, AdminSettingContainer, AdminSettingForm, AdminPopupText, AdminPopupInput, AdminPopupSelectBox, AdminPopupCheckbox, AddTableRowButton } from '../../../AdminPage-styling';
import { DataModel, Attribute, AttributeType, TDataModelSchema, dataModelSchema } from '../../../../Types';
import { PopupOverlay, CloseButton } from '../../../../ErrandsPage/ErrandsPage-styling';
import { useDataModelAttributeTypes } from '../../../../Queries/useDataModelAttributeTypes';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AuthorizationError } from '../../../../AuthorizationPages/Authorization-styling';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Button } from '@mui/material';
import { queryClient } from '../../../../lib/queryClient';
import { Checkbox } from '@mantine/core';

type EditSchemaPopupProps = {
  selectedModel: DataModel;
  onClose: () => void;
}

const EditSchemaPopup = ({ selectedModel, onClose }: EditSchemaPopupProps) => {
  // const [attributes, setAttributes] = useState<Attribute[]>([]);
  // const [modelName, setModelName] = useState(selectedModel.name || '');
  // const [modelDescription, setModelDescription] = useState(selectedModel.description || '');

  const { data: attributeTypes, isLoading, error } = useDataModelAttributeTypes();

  const { 
    handleSubmit, 
    register,
    control,
    formState: { errors, isSubmitting }
  } = useForm<TDataModelSchema>({
    resolver: zodResolver(dataModelSchema),
    defaultValues: {
      id: selectedModel.id,
      name: selectedModel.name || '',
      description: selectedModel.description || '',
      attributes: Object.entries(selectedModel.object_schema || {}).map(([name, details]) => {
        const detail = typeof details === 'string' ? JSON.parse(details) : details;
        return {
          name,
          type: detail.type,
          description: detail.description,
          required: detail.required,
        };
      }),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes',
  });

  const onSubmit = async (data: TDataModelSchema) => {
    try {
      const modelData: DataModel = {
        id: data.id || '',
        name: data.name,
        description: data.description,
        object_schema: data.attributes.reduce((acc: Record<string, Attribute>, attr) => {
          acc[attr.name] = {
            name: attr.name,
            type: attr.type,
            description: attr.description,
            required: attr.required,
          };
          return acc;
        }, {}),
      };
      console.log('modelData', modelData);

      if (!selectedModel.id) {
        await ax.post('/custom_data_model', modelData).then((response) => {
          queryClient.setQueryData(['custom_data_model', 'all'], (oldData: DataModel[]) => {
            return [response.data, ...oldData];
          });
          onClose();
        });
      } else {
        await ax.patch('/custom_data_model', modelData).then((response) => {
          queryClient.setQueryData(['custom_data_model', 'all'], (oldData: DataModel[]) => {
            return oldData.map((model) => model.id === response.data.id ? response.data : model);
          });
          onClose();
        });
      }
    } catch (error) {
      toast.error('Failed to save custom data model.');
    }
  };
  

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading attribute types.</div>;

  return (
    <PopupOverlay>
      <AdminPopupWindow style={{ width: '80%', maxWidth: '1000px' }}>
        <CloseButton onClick={onClose} />
        <h2>Edit Data Model</h2>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
          <AdminSettingContainer>
            <AdminPopupText>Name</AdminPopupText>
            <AdminPopupInput 
              {...register('name')}
              type="text" 
              placeholder="Name"
              style={{
                border: errors.name ? '1px solid red' : '1px solid #333',
              }}
            />
            {errors.name && <AuthorizationError>{errors.name.message}</AuthorizationError>}
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminPopupText>Description</AdminPopupText>
            <AdminPopupInput 
              {...register('description')}
              type="text" 
              placeholder="Description"
              style={{
                border: errors.description ? '1px solid red' : '1px solid #333',
              }}
            />
            {errors.description && <AuthorizationError>{errors.description.message}</AuthorizationError>}
          </AdminSettingContainer>
          <AdminPopupText>Attributes</AdminPopupText>
          <table style={{ width: '100%', backgroundColor: '#f0f0f0', color: '#333' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Required</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => (
                <tr key={field.id}>
                  <td>
                    <AdminPopupInput 
                      {...register(`attributes.${index}.name`)}
                      type="text"
                      placeholder="Name"
                      style={{
                        border: errors.attributes?.[index]?.name ? '1px solid red' : '1px solid #333',
                      }}
                    />
                  </td>
                  <td>
                    <AdminPopupSelectBox 
                      {...register(`attributes.${index}.type`)}
                      style={{
                        border: errors.attributes?.[index]?.type ? '1px solid red' : '1px solid #333',
                      }}
                    >
                      {attributeTypes?.map((type) => (
                        <option key={type.name} value={type.name}>{type.name}</option>
                      ))}
                    </AdminPopupSelectBox>
                  </td>
                  <td>
                    <AdminPopupInput 
                      {...register(`attributes.${index}.description`)}
                      type="text"
                      placeholder="Description"
                      style={{
                        border: errors.attributes?.[index]?.description ? '1px solid red' : '1px solid #333',
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox 
                      {...register(`attributes.${index}.required`)}
                    />
                  </td>
                  <td>
                    <Button 
                      onClick={() => remove(index)}
                      sx={{
                        color: '#d33',
                        '&:hover': {
                          color: '#d02020',
                        }
                      }}
                    >
                      <FaTrash size={20} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {errors.attributes && <AuthorizationError>{errors.attributes.message}</AuthorizationError>}
          <AddTableRowButton 
            type="button" 
            onClick={() => append({ 
              name: '', 
              type: attributeTypes?.[0]?.name || '', 
              description: '', 
              required: false 
            })}
          >
            <FaPlus /> Add Attribute
          </AddTableRowButton>
          <AdminButton type="submit">Save data model</AdminButton>
        </form>
      </AdminPopupWindow>
    </PopupOverlay>
  );
};

export default EditSchemaPopup; 