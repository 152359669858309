import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { FileData } from "../Types";


export const useFiles = () => {
  return useQuery<FileData[], Error>({
    queryKey: ['file', 'all'],
    queryFn: (): Promise<FileData[]> => ax.get('/file/all/').then(res => res.data),
    refetchOnReconnect: true,
  });
};