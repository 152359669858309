import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";



export const useEmailSources = () => {
  return useQuery({
    queryKey: ['email_source', 'all'],
    queryFn: () => ax.get('/email_source/all/').then(res => res.data),
    refetchOnReconnect: true,
  });
};