import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { User } from "../Types";



export const useChangeUnitMutation = () => {
  const queryClient = useQueryClient();

  const changeUnitMutation = useMutation({
    mutationFn: (unitId: string) => {
      return ax.post(`/users/change-unit-and-org/?new_unit_id=${unitId}`);
    },
    onSuccess: (_, unitId) => {
      queryClient.setQueryData(['users', 'me'], (old: User) => {
        return {
          ...old,
          unit_id: unitId
        };
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          // Add the query keys you want to KEEP here
          const protectedQueries = [
            ['role', 'all'],
            ['users', 'me'],
            ['pricing_plan', 'all'],
            ['feedback_source_type', 'all'],
            ['feedback', 'type', 'filter'],
            ['interface_language', 'all'],
            ['assistant_language', 'all'],
            ['organization'],
            ['user_unit_access', 'all', 'current_user'],
            ['current', 'tab', 'admin'],
            ['current', 'subtab', 'admin'],
            ['users', 'accessible-unit-org']
          ];
          return !protectedQueries.some(protectedKey => {
            const currentKey = query.queryKey as unknown[];
            // Check if arrays match up to the length of the protected key
            return protectedKey.every((key, index) => currentKey[index] === key);
          });
        },
      });
    }
  })

  return changeUnitMutation;
}