import { Text, Stack } from "@mantine/core";
import { StandardBase } from "../Components/StandardBase";
import { MainContent } from "../LoginPage/LoginPage-styling";



const SuccessPage = () => {
  return (
    <StandardBase>
      <MainContent>
        <Stack>
          <Text c="white" fz="28">
            Allt gick bra!
          </Text>
          <Text c="white" size="xl">
            Du kan nu stänga den här fliken och fortsätta med konfigurationen av din inkorg.
          </Text>
        </Stack>
      </MainContent>
    </StandardBase>
  );
};

export default SuccessPage;