import { createTheme, MantineProvider } from '@mantine/core';

export const theme = createTheme({
  primaryColor: 'dark',
  colors: {
    primary: ['#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1', '#0f86a1'],
  },
  components: {
    Stepper: {
      defaultProps: {
        color: '#0f86a1',
        size: 'sm'
      },
      styles: {
        root: {
          padding: '1rem 0',
          '& *': {
            fontFamily: 'inherit'  // Use the app's default font
          }
        },
        separator: {
          height: '2px'
        },
        step: {
          padding: '0.5rem'
        },
        stepLabel: {
          fontSize: '0.9rem',
          marginTop: '0.5rem'
        },
        stepDescription: {
          fontSize: '0.8rem'
        }
      }
    },
    Modal: {
      defaultProps: {
        overlayProps: {
          opacity: 0.55,
          blur: 3,
        },
        transitionProps: {
          transition: 'fade',
          duration: 200,
        },
      },
      styles: {
        root: {
          zIndex: 1000,
        },
        content: {
          zIndex: 1001,
        },
        header: {
          marginBottom: '1rem',
        },
      },
    },
  },
}); 