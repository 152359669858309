import { useQuery } from '@tanstack/react-query';
import { ax } from '../Utils';

interface TopicTopDocumentsParams {
  topic_id?: string;
  limit?: number;
}

export const useTopicTopDocuments = ({ topic_id, limit }: TopicTopDocumentsParams) => {
  return useQuery({
    queryKey: ['topicTopDocuments', topic_id, limit],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (topic_id) {
        params.append('topic_id', topic_id);
      }
      if (limit) {
        params.append('limit', limit.toString());
      }
      const url = `/document_topic/all/list/?${params.toString()}`;
      const response = await ax.get(url);
      return response.data;
    }
  });
}; 