import styled, { keyframes } from 'styled-components';

export const AssistantSection = styled.div`
  height: calc(100% - 14px);
  flex: 1;
  width: 30%;
  min-width: 210px;
  min-height: 320px;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  display: flex;
  border: solid;
  border-color: #444;
  border-width: 6px 6px 6px 3px;
  border-radius: 6px;
  align-self: safe start;
`;//toppen på border kan vara 27px

export const AssistantHeader = styled.a`
  font-size: 28px;
  color: #fff;
  margin: 10px;
`;

export const AISection = styled.div`
  height: calc(100% - 5px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  padding: 5px 5px 0px 5px;
`;

export const ConversationSection = styled.div`
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  display: flex;
  flex: 1;
`;

export const CopilotChatItem = styled.div<{$isCopilot: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  background-color: ${(props) => (props.$isCopilot ? "" : "rgba(0, 0, 0, 0.2)")};
  border-radius: ${(props) => (props.$isCopilot ? "" : "6px")};
  padding-bottom: 5px;
  position: relative;
`;

export const ChatProfileImgContainer = styled.div<{$isCopilot: boolean}>`
  margin: 10px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 18px;
  background-color: ${(props) => (props.$isCopilot ? "#fff" : "rgba(0, 0, 0, 0.2)")};
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 35px;
  color: ${(props) => (props.$isCopilot ? "#0f86a1" : "#fff")};
  font-size: 24px;
  font-weight: 500;
  overflow: clip;
  display: flex;
  z-index: 1;
  /* padding-top: 5px; */
`;

export const CopilotProfileImg = styled.img`
  max-width: 29px;
  max-height: 29px;
`;

export const CopilotChatItemText = styled.p`
  font-size: 16px;
  color: #fff;
  margin: 15px 15px 5px 5px;
  line-height: 1.25;
  white-space: pre-line;
  word-break: break-word;
`;

export const CopilotInputSection = styled.div`
  min-height: 50px;
  width: 100%;
  padding: 10px;
  display: flex;
  position: relative;
`;

export const CopilotInput = styled.textarea`
  height: auto;
  max-height: 100px;
  width: calc(100% - 75px);
  padding: 15px 50px 15px 15px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  color: #fff;
  resize: none;
  font-size: 15px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  margin-bottom: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  line-height: 1.25;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 7px #000;
  }

  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

export const SendIconButton = styled.button`
  position: absolute;
  bottom: 22px;
  right: 25px;
  cursor: pointer;
  font-size: 1.6rem;
  color: #212121;
  border: none;
  background-color: transparent;
  
  &:hover {
    bottom: 21px;
    right: 24px;
    font-size: 1.8rem;
    }
`;

const blink = keyframes`
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
`;

export const BlinkingSpan = styled.span`
  animation: ${blink} 1.5s infinite;
  display: inline-block;
  font-size: 16px;
`;

export const TextEditorBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 10px);
  padding: 5px 5px 5px 5px;
  border-top: solid 2px rgba(0, 0, 0, 0.2);
`;

export const WordCountText = styled.div`
  color: #fff;
  font-size: 12px;
  margin-left: 5px;
`;

export const SaveMarketingButton = styled.button`
  background-color: #0f86a1;
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 500;
  box-sizing: border-box;
  box-shadow: 0 0 4px #000;
  margin-right: 5px;

  
  &:hover {
    background-color: #0a6073;
    box-shadow: 0 0 4px #0f86a1;
  }
`;

export const ToolsGroup = styled.div`
  button {
    background: none;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    margin: 5px 5px;
    color: #ccc;
    padding-top: 2px;

    &:hover {
      color: #fff;
      background-color: #222;
      cursor: pointer;
    }

    &.is-active {
      background-color: #222;
      box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
    }

    &.is-disabled {
      color: #999;
      box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.4);

      &:hover {
        color: #999;
        background-color: transparent;
        cursor: default;
      }
    }

  }
`;