
import { TAddUserSchema, User, UserUnitAccess, addUserSchema } from "../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { ax } from "../../Utils";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthorizationError } from "../../AuthorizationPages/Authorization-styling";
import { AdminButton, AdminPopupCheckbox, AdminPopupCheckboxContainer, AdminPopupCheckboxLabel, AdminPopupCheckboxReadOnly, AdminPopupInput, AdminPopupPermissionsContainer, AdminPopupSelectBox, AdminPopupText, AdminPopupWindow, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingSelectBox, AdminSettingText } from "../AdminPage-styling";
import Swal from "sweetalert2";
import { CloseButton, CloseErrandWindow, PopupOverlay, PopUpTitleContainer } from "../../ErrandsPage/ErrandsPage-styling";
import { useUserMeta } from "../../Queries/useUserMeta";
import { useOrganizationUsersExclUnit } from "../../Queries/useOrgUsersExclUnit";
import { useRoleList } from "../../Queries/useRoleList";
import { useQueryClient } from "@tanstack/react-query";



export default function AddUserPopup( {closePopup}: {closePopup: () => void}) {
  const { data: userMeta } = useUserMeta();
  const { data: organizationUsers } = useOrganizationUsersExclUnit(userMeta?.unit_id);
  const { data: roles } = useRoleList();

  const queryClient = useQueryClient();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
    watch,
  } = useForm<TAddUserSchema>({
    resolver: zodResolver(addUserSchema),
    defaultValues: {
      existingUserId: "",
      reader: true,
      feedback: false,
      marketing: false,
      admin: false,
    },
  });

  const existingUserId = watch("existingUserId");

  const onSubmit = async (data: TAddUserSchema) => {
    console.log(data);
    if (!userMeta) {
      Swal.fire({
        title: "Ingen aktiv användare hittades!",
        text: "Testa att logga in igen!",
        icon: "error",
      });
      return;
    }

    if (data.existingUserId) {
      const requestBody = {
        user_id: data.existingUserId,
        unit_id: userMeta.unit_id,
        org_id: userMeta.org_id,
        role_ids: roles?.filter((role) => data[role.name as keyof TAddUserSchema]).map((role) => role.id),
      };

      try {
        await ax.post('/user_unit_access/many_roles/', requestBody)
          .then((response) => {
            console.log("response: ", response);
            queryClient.setQueryData(['user_unit_access', 'all', 'list', {unit_id: userMeta.unit_id}], (oldData: UserUnitAccess[]) => {
              if (!oldData) return [response.data];
              return [...oldData, response.data];
            });
            
            queryClient.setQueryData(['users', 'all', 'exclude_current_unit', {unit_id: userMeta.unit_id}], (oldData: User[]) => {
              if (!oldData) return [];
              return oldData.filter((user) => user.id !== data.existingUserId);
            });
            closePopup();
            Swal.fire({
              title: "Användaren har lagts till!",
              text: "Användaren har lagts till med de valda behörigheterna!",
              icon: "success",
            });
          })
      }
      catch (error) {
        console.log(error);
        Swal.fire({
          title: "Ett problem uppstod!",
          text: "Försök igen senare.",
          icon: "error",
        });
        return;
      }

    }

    else {
      const requestBody = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
      };

      try {
        await ax.post('/users/register/', requestBody)
          .then((response) => {
            const requestBody2 = {
              user_id: response.data.id,
              unit_id: response.data.unit_id,
              org_id: response.data.org_id,
              role_ids: roles?.filter((role) => data[role.name as keyof TAddUserSchema]).map((role) => role.id),
            };
            ax.post('/user_unit_access/many_roles/', requestBody2)
              .then((response) => {
                queryClient.setQueryData(['user_unit_access', 'all', 'list', {unit_id: userMeta.unit_id}], (oldData: UserUnitAccess[]) => {
                  if (!oldData) return [response.data];
                  return [...oldData, response.data];
                });
                
                closePopup();
                Swal.fire({
                  title: "Användaren har lagts till!",
                  text: "Användaren har lagts till med de valda behörigheterna!",
                  icon: "success",
                });
              })
            });
      }
      catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 400) {
            Swal.fire({
              title: "Registrering av användaren misslyckades!",
              text: "Kontrollera att användaren inte redan finns i systemet.",
              icon: "error",
            });
          } else {
            // General error message if not a 400 status
            Swal.fire({
              title: "Registrering av användaren misslyckades!",
              text: "Försök igen senare.",
              icon: "error",
            });
          }
        } else {
          // If it's not an AxiosError, handle as a general unknown error
          Swal.fire({
            title: "Ett problem uppstod!",
            text: "Försök igen senare.",
            icon: "error",
          });
        }
        return;
      }
      const requestBody3 = {
        email: data.email,
      };

      // try {
      //   await ax.post('/auth/request-verify-token', requestBody3);
      // }
      // catch (error) {
      //   Swal.fire({
      //     title: "Bekräftelsemejlet gick inte att skicka!",
      //     text: "Försök igen senare.",
      //     icon: "error",
      //   });
      //   return;
      // }

      toast.success(`Ett bekräftelsemejl har skickats till ${data.email}!`);
    }
    reset();
  };

  console.log("Form errors:", errors);
  console.log("Is submitting:", isSubmitting);

  return (
    <PopupOverlay onClick={closePopup}>
      <CloseErrandWindow onClick={(e) => e.stopPropagation()}>
        <PopUpTitleContainer>Lägg till användare på {userMeta?.unit_name}</PopUpTitleContainer>
        <CloseButton onClick={closePopup} />
        <AdminSettingForm onSubmit={handleSubmit(onSubmit)}>
          <AdminSettingContainer>
            <AdminPopupText>Existerande användare i {userMeta?.org_name}</AdminPopupText>
            <AdminPopupSelectBox
              {...register("existingUserId")}
              id="existingUserId"              
            >
              <option value="">Välj en användare</option>
              {organizationUsers?.map((user) => (
                <option key={user.id} value={user.id}>{user.email}</option>
              ))}
            </AdminPopupSelectBox>
          </AdminSettingContainer>
          eller...
          <AdminSettingContainer>
            <AdminPopupText>Ny användare</AdminPopupText>
          </AdminSettingContainer>
          <AdminSettingContainer>
            {/* <AdminPopupText>Förnamn</AdminPopupText> */}
            <AdminPopupInput
              {...register("firstName")}
              type="text"
              placeholder="Förnamn"
              id="firstName"
              disabled={!!existingUserId}
            />
            {errors.firstName && (
              <AuthorizationError>{`${errors.firstName.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            {/* <AdminPopupText>Efternamn</AdminPopupText> */}
            <AdminPopupInput
              {...register("lastName")}
              type="text"
              placeholder="Efternamn"
              id="lastName"
              disabled={!!existingUserId}
            />
            {errors.lastName && (
              <AuthorizationError>{`${errors.lastName.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            {/* <AdminPopupText>E-postadress</AdminPopupText> */}
            <AdminPopupInput
              {...register("email")}
              type="email"
              placeholder="E-postadress"
              id="email"
              disabled={!!existingUserId}
            />
            {errors.email && (
              <AuthorizationError>{`${errors.email.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <AdminPopupText>Behörigheter</AdminPopupText>
            <AdminPopupPermissionsContainer>
              <AdminPopupCheckboxContainer>
                <AdminPopupCheckboxLabel>Reader</AdminPopupCheckboxLabel>
                <AdminPopupCheckboxReadOnly
                  {...register("reader")}
                  //defaultChecked={true}
                  checked={true}
                  readOnly
                />
              </AdminPopupCheckboxContainer>
              <AdminPopupCheckboxContainer>
                <AdminPopupCheckboxLabel>Feedback</AdminPopupCheckboxLabel>
                <AdminPopupCheckbox
                  {...register("feedback")}
                />
              </AdminPopupCheckboxContainer>
              <AdminPopupCheckboxContainer>
                <AdminPopupCheckboxLabel>Marknadsföring</AdminPopupCheckboxLabel>
                <AdminPopupCheckbox
                  {...register("marketing")}
                />
              </AdminPopupCheckboxContainer>
              <AdminPopupCheckboxContainer>
                <AdminPopupCheckboxLabel>Admin</AdminPopupCheckboxLabel>
                <AdminPopupCheckbox
                  {...register("admin")}
                />
              </AdminPopupCheckboxContainer>
            </AdminPopupPermissionsContainer>
          </AdminSettingContainer>


          <AdminButton
            disabled={isSubmitting}
            type="submit"
          >
            Lägg till
          </AdminButton>
        </AdminSettingForm>
      </CloseErrandWindow>
    </PopupOverlay>
  );
}