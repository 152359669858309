import React, { useState } from "react";
import AppLayout from "./MainPage";
import LoginPage from "./LoginPage/LoginPage";
import AboutPage from "./AboutPage/AboutPage";
import ContactPage from "./ContactPage/ContactPage";
import UserPage from "./UserPage/UserPage";
import AdminPage from "./AdminPage/AdminPage";
import TemplatesPage from "./TemplatesPage/TemplatesPage";
import ErrandsPage from "./ErrandsPage/ErrandsPage";
import { SearchPage } from "./SearchPage/SearchPage";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CataloguePage from "./CataloguePage/CataloguePage";
import { User } from "./Types";
import StatsPage from "./StatsPage/StatsPage";
//import LandingPage from "./LandingPage/LandingPage";
import MarketingPage from "./MarketingPage/MarketingPage";
import FeedbackPage from "./FeedbackPage/FeedbackPage";
import { MantineProvider } from "@mantine/core";
//import { Notifications } from "@mantine/notifications";
import '@mantine/core/styles/global.css';
// import '@mantine/notifications/styles.css';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignupPage from "./SignupPage/SignupPage";
import ForgotPasswordPage from "./AuthorizationPages/ForgotPasswordPage";
import ResetPasswordPage from "./AuthorizationPages/ResetPasswordPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import VerificationPage from "./AuthorizationPages/VerificationPage";
import NewEmailInbox from "./EmailInboxPage/EmailInboxPage";
import ExternalChatbot from "./OldExternalChatbot/ExternalChatbot";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import ZoidExternalChatbot from "./ZoidExternalChatbot/ZoidExternalChatbot";
import ExternalChatbotTestPage from "./ZoidExternalChatbot/ExternalChatbotTestPage";
import { queryClient } from "./lib/queryClient";
import { UnauthorizedHandler } from "./Handlers/UnauthorizedHandler";
import { ProtectedRoute } from "./Handlers/ProtectedRoute";
import NoAccessPage from "./NotFoundPage/NoAccessPage";
import MarketingPageNew from './MarketingPageNew/MarketingPageNew';
import TextAnalyticsPage from "./AnalyticsPage/TextAnalyticsPage";
import { theme } from "./theme/mantine-config";
// Do this once in your application root file
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SuccessPage from "./NotFoundPage/SuccessPage";
import SomethingWentWrong from "./NotFoundPage/SomethingWentWrong";

dayjs.extend(customParseFormat);
// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       staleTime: Infinity,
//       gcTime: 1000 * 60 * 60 * 24,
//     },
//   },
// });

// Set up a persister using local storage
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

// Persist the query client with local storage
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: 1000 * 60 * 60 * 24, // Optional: data older than this will not be restored
});

const App = () => {

  //queryClient.prefetchQuery({queryKey: ['users', 'me'], queryFn: getCurrentUser});

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Zoom}
        />
        <Router>
          <UnauthorizedHandler />
          <div>
            <Routes>
              <Route path="/login" 
                element={<LoginPage />}
              />
              {/* <Route path="/errandsold" element={<AppLayout />} /> */}
              {/* <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} /> */}
              <Route path="/user" 
                element={
                  <ProtectedRoute requiredRole="reader">
                    <UserPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/stats" 
                element={
                  <ProtectedRoute requiredRole="reader">
                    <StatsPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/admin" 
                element={
                  <ProtectedRoute requiredRole="admin">
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/admin/:tab" 
                element={
                  <ProtectedRoute requiredRole="admin">
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/admin/templates" element={<TemplatesPage />} />
              {/* <Route path="/admin/email/inbox/new" element={<NewEmailInbox />} /> */}
              {/* <Route path="/errands" element={<ErrandsPage />} /> */}
              <Route path="/feedback" 
                element={
                  <ProtectedRoute requiredRole="feedback">
                    <FeedbackPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/feedback/:id" 
                element={
                  <ProtectedRoute requiredRole="feedback">
                    <FeedbackPage />
                  </ProtectedRoute>
                }
              />

              <Route path="/marketing_old" 
                element={
                  <ProtectedRoute requiredRole="marketing">
                    <MarketingPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/marketing_old/:id" 
                element={
                  <ProtectedRoute requiredRole="marketing">
                    <MarketingPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/knowledge-base" 
                element={
                  <ProtectedRoute requiredRole="reader">
                    <CataloguePage />
                  </ProtectedRoute>
                }
              />
              <Route path="/external-chatbot-test" 
                element={
                  <ProtectedRoute requiredRole="admin">
                    <ExternalChatbotTestPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/login/forgot" element={<ForgotPasswordPage />} />
              {/* <Route path="/login/reset/email/:email/token/:token" element={<ResetPasswordPage />} /> */}
              <Route path="/login/reset/email/" element={<ResetPasswordPage />} />
              {/* <Route path="/login/verify/:token" element={<VerificationPage />} /> */}

              <Route path="/chatbot" element={<ZoidExternalChatbot />} />
              <Route path="/no-access" element={<NoAccessPage />} />

              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="*" element={<NotFoundPage />} />

              <Route path="/marketing" 
                element={
                  <ProtectedRoute requiredRole="marketing">
                    <MarketingPageNew />
                  </ProtectedRoute>
                }
              />
              <Route path="/marketing/:campaignId?/:variantId?/:contentId?" 
                element={
                  <ProtectedRoute requiredRole="marketing">
                    <MarketingPageNew />
                  </ProtectedRoute>
                }
              />
              <Route path="/admin/sources/:subTab" 
                element={
                  <ProtectedRoute requiredRole="admin">
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/something-went-wrong" element={<SomethingWentWrong />} />
              <Route path="/analytics" element={<TextAnalyticsPage />} />
            </Routes>
          </div>
        </Router>
      </MantineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
