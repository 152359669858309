import React, { useEffect, useMemo, useState } from 'react';
import { AdminButton, AdminCategoryTitle } from '../../../AdminPage-styling';
import { ax } from '../../../../Utils';
import Table from '../../../AdminComponents/Table';
import { toast } from 'react-toastify';
import EditSchemaPopup from './EditSchemaPopup';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomDataModels } from '../../../../Queries/useCustomDataModels';
import { DataModel } from '../../../../Types';
import { Button } from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { ColumnDef } from '@tanstack/react-table';


const CustomDataModelsTable = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedModel, setSelectedModel] = useState<DataModel | null>(null);
  
  const { data: models, isLoading, error } = useCustomDataModels();
  
  const queryClient = useQueryClient();

  const handleEdit = (model: DataModel) => {
    setSelectedModel(model);
    setShowEditPopup(true);
  };

  const handleAddModel = () => {
    const newModel: DataModel = {
      id: '',
      name: '',
      description: '',
      object_schema: {},
    };
    setSelectedModel(newModel);
    setShowEditPopup(true);
  };

  const handleClickDelete = (modelId: string) => {
    Swal.fire({
      title: 'Vill du radera denna datamodell?',
      text: 'Du kommer inte kunna återställa datamodellen som har raderats!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, radera den!',
      confirmButtonColor: '#d33',
      cancelButtonText: 'Avbryt'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(modelId);
      }
    });
  };

  const handleDelete = async (modelId: string) => {
    if (modelId) {
      try {
        ax.delete(`/custom_data_model/${modelId}`);
        queryClient.setQueryData(['custom_data_model', 'all'], (oldData: DataModel[]) => {
          return oldData.filter(model => model.id !== modelId);
        });
      } catch (error) {
        toast.error('Failed to delete custom data model.');
        return;
      }
    }
  };

  const columns = useMemo<ColumnDef<DataModel, any>[]>(
    () => [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (info) => (
        info.getValue()
      ),
    },
    {
      header: 'Description',
      accessorKey: 'description',
      cell: (info) => (
        <div style={{
          maxHeight: '2.4em',
          lineHeight: '1.2',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'normal'
        }}>
          {info.getValue()}
        </div>
      ),
    },
    {
      header: 'Edit',
      cell: (info) => (
        <Button 
          onClick={() => handleEdit(info.row.original)}
          sx={{
            color: '#f0f0f0',
            '&:hover': {
              color: '#ccc',
            }
          }}
        >
          <FaEdit size={20} />
        </Button>
      ),
    },
    {
      header: 'Delete',
      cell: (info) => (
        <Button 
          onClick={() => handleClickDelete(info.row.original.id)}
          sx={{
            color: '#f0f0f0',
            '&:hover': {
              color: '#d33',
            }
          }}
        >
          <FaTrash size={20} />
        </Button>
      ),
    }
  ], [models]);

  console.log('Table Data:', models);

  return (
    <div>
      <AdminCategoryTitle>Custom Data Models</AdminCategoryTitle>
      <Table columns={columns} data={models || []} />
      <AdminButton onClick={handleAddModel}>Create New Model</AdminButton>
      {showEditPopup && selectedModel && (
        <EditSchemaPopup
          selectedModel={selectedModel}
          onClose={() => {
            setShowEditPopup(false);
            setSelectedModel(null);
          }}
        />
      )}
    </div>
  );
};

export default CustomDataModelsTable; 