import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { EmailSource } from "../../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton, RefreshButton } from "../../AdminPage-styling";
import { useFeedbackSourceList } from "../../../Queries/useFeedbackSourceList";
import Swal from "sweetalert2";
import { FaSync, FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { ax, formatDate } from "../../../Utils";
import { useQueryClient } from "@tanstack/react-query";
import '../../../CSS/swal.css';
import { useCurrentUser } from "../../../Queries/useCurrentUser";
import { useMemo, useState, useEffect, useRef } from "react";
import { ColumnDef } from "@tanstack/react-table";
import Table from "../Table";
import { Button, Checkbox, Group, Modal, TextInput, Stack, Loader, Text, Badge, Title } from "@mantine/core";
import { useEmailSources } from "../../../Queries/useEmailSources";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import { GoogleButton, MicrosoftButton } from "../../../Components/AuthButtons";

const EmailInboxList = () => {
  // Configure inbox modal
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedInbox, setSelectedInbox] = useState<EmailSource | null>(null);
  
  // Authentication modal state - simplified approach
  const [authModalOpened, { open: openAuthModal, close: closeAuthModal }] = useDisclosure(false);
  const [authState, setAuthState] = useState({
    inboxId: '',
    authUrl: '',
    provider: '', // 'gmail' or 'outlook'
    isAuthenticating: false,
    isSuccess: false,
    isInitialView: true, // New state to track initial view with provider buttons
    abortController: null as AbortController | null
  });

  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const { data: emailSources } = useEmailSources();

  const schema = z.object({
    name: z.string().min(1, { message: 'Namn krävs' })
  });

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  // Clean up authentication polling when modal closes
  useEffect(() => {
    if (!authModalOpened && authState.abortController) {
      authState.abortController.abort();
      setAuthState(prev => ({
        ...prev,
        abortController: null,
        isAuthenticating: false
      }));
    }
  }, [authModalOpened]);

  const handleDeleteInboxPopup = (inboxId: string, inboxName: string, inboxEmail: string) => {
    Swal.fire({
      title: `Är du säker på att du vill ta bort inkorgen ${inboxName} (${inboxEmail})?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort inkorg",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInbox(inboxId);
      }
    });
  };

  const deleteInbox = async (inboxId: string) => {
    try {
      await ax.delete(`/email_source/${inboxId}?hard_delete=true`)
        .then(() => {
          queryClient.setQueryData(['email_source', 'all'], (oldData: EmailSource[]) => {
            return oldData.filter((inbox) => inbox.id !== inboxId);
          });
          Swal.fire({
            title: "Inkorgen borttagen!",
            icon: "success"
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Borttagning misslyckades!",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  // First step: Open modal and get email source info
  const handleAuthenticateInbox = async (inboxId: string) => {
    try {
      // Find the email source to determine provider
      const emailSource = emailSources?.find((source: EmailSource) => source.id === inboxId);
      
      // Set initial state with provider type
      setAuthState({
        inboxId,
        authUrl: '',
        provider: emailSource?.provider || 'gmail', // Default to gmail if not found
        isAuthenticating: false,
        isSuccess: false,
        isInitialView: true,
        abortController: null
      });
      
      // Open the modal with provider selection
      openAuthModal();
      
    } catch (error: any) {
      console.error('Authentication error:', error);
      Swal.fire({
        title: "Kunde inte förbereda autentisering!",
        text: "Försök igen senare eller kontakta support",
        icon: "error"
      });
    }
  };
  
  // Second step: Start the actual authentication after provider button is clicked
  const startAuthentication = async (provider: string) => {
    try {
      // Create an AbortController for this auth session
      const controller = new AbortController();
      
      // Update state to indicate authentication is in progress
      setAuthState(prev => ({
        ...prev,
        isAuthenticating: true,
        isInitialView: false,
        abortController: controller
      }));
      
      // Get auth URL
      const authUrlResponse = await ax.get(`/email_source/oauth/auth_url?email_source_id=${authState.inboxId}`);
      
      // Update state with the auth URL
      setAuthState(prev => ({ ...prev, authUrl: authUrlResponse.data }));
      
      // Open the auth URL in a new tab
      window.open(authUrlResponse.data, '_blank');
      
      // Start polling for auth status
      pollAuthStatus(authState.inboxId, controller.signal);
      
    } catch (error: any) {
      console.error('Authentication error:', error);
      Swal.fire({
        title: "Autentisering misslyckades!",
        text: "Försök igen senare eller kontakta support",
        icon: "error"
      });
      setAuthState(prev => ({ 
        ...prev, 
        isAuthenticating: false,
        isInitialView: true 
      }));
    }
  };
  
  const pollAuthStatus = async (inboxId: string, signal: AbortSignal) => {
    for (let i = 0; i < 120; i++) {  // 10 minute timeout
      // Check if polling was aborted (modal closed)
      if (signal.aborted) {
        console.log('Auth polling aborted');
        return;
      }
      
      try {
        const authStatus = await ax.get(`/email_source/oauth?obj_id=${inboxId}`);
        console.log('authStatus', authStatus.data.is_authenticated);
        
        if (authStatus.data.is_authenticated) {
          // Update authentication status
          setAuthState(prev => ({ 
            ...prev, 
            isAuthenticating: false,
            isSuccess: true,
            isInitialView: false
          }));
          
          // Update data in cache
          queryClient.invalidateQueries({ queryKey: ['email_source', 'all'] });
          return;
        }
      } catch (error) {
        if (signal.aborted) return;
        console.error('Error polling auth status:', error);
      }
      
      // Wait before next poll
      try {
        await new Promise((resolve) => {
          const timeoutId = setTimeout(resolve, 5000);
          signal.addEventListener('abort', () => {
            clearTimeout(timeoutId);
            resolve(undefined);
          }, { once: true });
        });
      } catch (error) {
        if (signal.aborted) return;
      }
    }
    
    // If we reach here, timeout occurred
    if (!signal.aborted) {
      setAuthState(prev => ({ 
        ...prev, 
        isAuthenticating: false,
        isInitialView: true
      }));
      Swal.fire({
        title: "Timeout!",
        text: "Autentiseringen tog för lång tid. Försök igen.",
        icon: "error"
      });
    }
  };

  const updateInbox = async (data: z.infer<typeof schema>) => {
    console.log('inboxName', data.name, 'inboxId', selectedInbox?.id);
    const requestBody = {
      "id": selectedInbox?.id,
      "name": data.name
    };
    try {
      await ax.patch(`/email_source`, requestBody)
        .then((response) => {
          queryClient.setQueryData(['email_source', 'all'], (oldData: EmailSource[]) => {
            return oldData.map((i) => i.id === selectedInbox?.id ? response.data : i);
          });
          close();
          Swal.fire({
            title: "Inkorg uppdaterad!",
            icon: "success"
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Uppdatering misslyckades!",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleActivateInbox = async (inboxId: string, is_active: boolean) => {
    console.log('inboxId', inboxId, 'is_active', is_active);
    const requestBody = {
      "id": inboxId,
      "is_active": is_active
    };
    try {
      await ax.patch(`/email_source`, requestBody)
        .then((response) => {
          console.log('response', response.data);
          queryClient.setQueryData(['email_source', 'all'], (oldData: EmailSource[]) => {
            return oldData.map((i) => i.id === inboxId ? response.data : i);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateDraftsInSource = async (inboxId: string, create_drafts_in_source: boolean) => {
    const requestBody = {
      "id": inboxId,
      "create_drafts_in_source": create_drafts_in_source
    };
    try {
      await ax.patch(`/email_source`, requestBody)
        .then((response) => {
          queryClient.setQueryData(['email_source', 'all'], (oldData: EmailSource[]) => {
            return oldData.map((i) => i.id === inboxId ? response.data : i);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo<ColumnDef<EmailSource, any>[]>(
    () => [
      {
        accessorFn: row => row.email_address,
        id: 'email',
        header: () => <span>E-postadress</span>,
        cell: info => {
          return (
            <>
              {info.getValue()}
            </>
          )
        },
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorKey: 'name',
        header: () => <span>Namn</span>,
        cell: info => {
          return (
            <>
              {info.getValue()}
            </>
          )
        },
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorKey: 'is_authenticated',
        header: () => <span>Autentiseringsstatus</span>,
        cell: info => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {info.getValue() ? (
                <>
                  <FaCheckCircle size={16} color="#12b886" />
                  <span style={{ color: '#12b886', fontWeight: 500 }}>Autentiserad</span>
                </>
              ) : (
                <Group>
                  <FaExclamationTriangle size={16} color="#fa5252" />
                  <Button  
                    size="xs"
                    h={24.8}
                    onClick={() => handleAuthenticateInbox(info.row.original.id)}
                    ml="auto"
                  >
                    Autentisera
                  </Button>
                </Group>
              )}
            </div>
          )
        },
      },
      {
        accessorKey: 'last_refreshed_at',
        header: () => <span>Senast synkad</span>,
        cell: info => {
          return (
            <>
              {info.getValue() ? formatDate(info.getValue()) : 'Ej synkad'}
            </>
          )
        },
      },
      {
        accessorFn: row => row.is_active,
        id: 'is_active',
        header: () => <span>Aktivera synkronisering</span>,
        cell: info => {
          return (
            <Checkbox
              checked={info.getValue()} 
              onChange={(e) => handleActivateInbox(info.row.original.id, e.target.checked)} 
            />
          )
        },
      },
      {
        accessorFn: row => row.create_drafts_in_source,
        id: 'create_drafts_in_source',
        header: () => <span>Skapa AI-utkast i inkorgen</span>,
        cell: info => {
          return (
            <Checkbox 
              checked={info.getValue()} 
              onChange={(e) => handleCreateDraftsInSource(info.row.original.id, e.target.checked)} 
            />
          )
        },
      },
      {
        accessorKey: 'edit_delete',
        enableSorting: false,
        header: () => <>Åtgärder</>,
        cell: info => {
          return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <ListConfigureButton onClick={() => {
                setSelectedInbox(info.row.original);
                open();
              }}>
                <FaWrench />
              </ListConfigureButton>
              <DeleteButton onClick={() => handleDeleteInboxPopup(info.row.original.id, info.row.original.name, info.row.original.email_address)}>
                <FaTrashCan />
              </DeleteButton>
            </div>
          )
        },
      }
    ],
    [handleDeleteInboxPopup, handleActivateInbox, handleAuthenticateInbox]
  );

  // Handle closing the auth modal
  const handleCloseAuthModal = () => {
    if (authState.abortController) {
      authState.abortController.abort();
    }
    closeAuthModal();
    // Reset to initial view for next time
    setAuthState(prev => ({ ...prev, isInitialView: true }));
  };

  // Render the provider-specific button
  const renderProviderButtons = () => {
    return (
      <>
        <Text ta="center" mb="md">För att autentisera din e-post, klicka på knappen nedan:</Text>
        <Stack gap="md" align="center">
          {authState.provider === 'gmail' || authState.provider === 'google' ? (
            <GoogleButton 
              onClick={() => startAuthentication('gmail')} 
              text="Fortsätt med Google"
            />
          ) : authState.provider === 'outlook' || authState.provider === 'microsoft' ? (
            <MicrosoftButton 
              onClick={() => startAuthentication('outlook')}
              text="Fortsätt med Microsoft"
            />
          ) : (
            <Text color="red">Okänd providerleverantör: {authState.provider}</Text>
          )}
        </Stack>
      </>
    );
  };

  return (
    <>
      <Table 
        columns={columns} 
        data={emailSources ?? []} 
      />
      
      {/* Configure Inbox Modal */}
      <Modal
        opened={opened}
        onClose={close}
        title="Konfigurera inkorg"
        size="sm"
        centered
      >
        <form onSubmit={handleSubmit(updateInbox)}>
          <TextInput
            label="Namnge inkorg"
            placeholder="Namn"
            defaultValue={selectedInbox?.name ?? ''}
            {...register('name')}
            mb="md"
          />
          <Group justify="flex-end">
            <Button type="button" variant="default" onClick={close}>
              Avbryt
            </Button>
            <Button color="#0f86a1" type="submit">
              Uppdatera inkorg
            </Button>
          </Group>
        </form>
      </Modal>
      
      {/* Authentication Modal */}
      <Modal
        opened={authModalOpened}
        onClose={handleCloseAuthModal}
        title="Autentisera e-postkonto"
        size="md"
        centered
      >
        {authState.isInitialView ? (
          // Initial view with provider selection
          <Stack justify="center" align="center" mt="xl" gap="md">
            {renderProviderButtons()}
          </Stack>
        ) : authState.isAuthenticating ? (
          // Authentication in progress
          <Stack justify="center" align="center" mt="xl" gap="md">
            <Text ta="center">Vänligen autentisera din e-post i det nya fönstret</Text>
            <Text ta="center" size="sm">
              <Link to={authState.authUrl} target="_blank">
                Klicka här om inget nytt fönster öppnats
              </Link>
            </Text>
            <Loader color="#0f86a1" size="md" />
            <Text ta="center">Inväntar autentisering...</Text>
          </Stack>
        ) : authState.isSuccess ? (
          // Authentication success
          <Stack justify="center" align="center" mt="xl" gap="md">
            <FaCheckCircle size={50} color="#0f86a1" />
            <Text ta="center" size="lg" fw={600}>Autentisering lyckades!</Text>
            <Text ta="center">Ditt e-postkonto har nu autentiserats och är redo att användas.</Text>
            <Button 
              color="#0f86a1" 
              onClick={handleCloseAuthModal}
              mt="md"
            >
              Stäng
            </Button>
          </Stack>
        ) : (
          <Text ta="center">Förbereder autentisering...</Text>
        )}
      </Modal>
    </>
  );
};

export default EmailInboxList;