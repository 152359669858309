import styled, { keyframes } from 'styled-components';

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: 10px;
  overflow: auto;

  @media (max-height: 600px) {
    align-items: flex-start;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const LoginBox = styled.div`
  border: none;
  padding: 30px;
  border-radius: 12px;
  width: 100%;
  height: auto;
  min-height: auto;
  max-width: 420px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${fadeIn} 0.5s ease-out;
  transition: box-shadow 0.3s ease;
  overflow-y: auto;
  
  @media (max-width: 480px) {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: auto;
    border-radius: 8px;
    padding: 20px;
  }

  @media (max-height: 600px) {
    max-height: calc(100vh - 40px);
    margin: 20px 0;
  }

  &:hover {
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  margin: 10px 0 25px;
  color: #084c61;
  font-weight: 600;
  letter-spacing: 0.5px;
  
  @media (max-width: 480px) {
    font-size: 28px;
    margin: 5px 0 20px;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  
  @media (max-height: 600px) {
    gap: 15px;
  }
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  
  @media (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

export const Input = styled.input<{ $error: boolean }>`
  display: block;
  width: 100%;
  height: 48px;
  margin-bottom: 4px;
  padding: 0 15px;
  border-width: ${(props) => (props.$error ? '2px' : '1px')};
  border-style: solid;
  border-color: ${(props) => (props.$error ? '#e74c3c' : '#dde2e5')};
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.25s ease;
  box-shadow: ${(props) => (props.$error ? '0 0 5px rgba(231, 76, 60, 0.3)' : 'none')};
  outline: none;
  box-sizing: border-box;
  background-color: ${(props) => (props.$error ? 'rgba(231, 76, 60, 0.03)' : '#fff')};

  &:hover {
    border-color: ${(props) => (props.$error ? '#e74c3c' : '#0f86a1')};
  }
  
  &:focus {
    border-color: ${(props) => (props.$error ? '#e74c3c' : '#0f86a1')};
    box-shadow: ${(props) => (props.$error ? '0 0 5px rgba(231, 76, 60, 0.4)' : '0 0 6px rgba(15, 134, 161, 0.3)')};
    background-color: white;
  }
  
  @media (max-height: 600px) {
    height: 42px;
  }
`;

export const Button = styled.button`
  background: linear-gradient(135deg, #0f86a1, #0a6073);
  color: #fff;
  border: none;
  margin: 20px 0 10px;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  transition: all 0.25s ease;
  box-shadow: 0 4px 10px rgba(10, 96, 115, 0.2);

  &:hover {
    background: linear-gradient(135deg, #0a6073, #084c61);
    box-shadow: 0 6px 12px rgba(10, 96, 115, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 6px rgba(10, 96, 115, 0.2);
  }
  
  @media (max-width: 480px) {
    padding: 10px;
    font-size: 16px;
  }
`;

export const ForgotPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
  @media (max-height: 600px) {
    margin-top: 10px;
  }
`;

export const ForgotPasswordText = styled.a`
  color: #0f86a1;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  transition: color 0.2s ease;

  &:hover {
    color: #084c61;
    text-decoration: underline;
  }
`;