import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Collapse, Paper, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import Plot from 'react-plotly.js';
import { StandardBase } from '../Components/StandardBase';
import { useTopics } from '../Queries/useTopics';
import { useTopicTimeseries } from '../Queries/useTopicTimeseries';
import { useTopicTopDocuments } from '../Queries/useTopicTopDocuments';
import { Topic, TopicDocument, TopicDocumentWithFeedback, TopicTimeseries } from '../Types';

const TextAnalyticsPage = () => {
  const { data: topics, isLoading: topicsLoading } = useTopics();
  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
  const [timePeriod, setTimePeriod] = useState('day');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  // Fetch timeseries data: if no topic is selected, overall timeseries; if selected, filtered by topic
  const { data: timeseriesData, isLoading: timeseriesLoading } = useTopicTimeseries({ topic_id: selectedTopic ? selectedTopic.id : undefined });

  // Fetch top documents only when a topic is selected
  const { data: topDocs, isLoading: docsLoading } = useTopicTopDocuments({ topic_id: selectedTopic ? selectedTopic.id : undefined, limit: 200 });

  // Prepare scatter chart data
  const xData = topics ? topics.map((topic: Topic) => topic.umap_2d[0]) : [];
  const yData = topics ? topics.map((topic: Topic) => topic.umap_2d[1]) : [];
  // Scale marker sizes using square root of document count
  const markerSizes = topics ? topics.map((topic: Topic) => Math.sqrt(topic.size) * 5) : [];
  const markerColors = topics ? topics.map((topic: Topic) => selectedTopic && topic.id === selectedTopic.id ? 'orange' : 'blue') : [];
  // Hover info includes title, description, and document count
  const hoverTexts = topics ? topics.map((topic: Topic) => `<b>${topic.title}</b><br>${topic.description}<br>Documents: ${topic.size}`) : [];
  // New: text labels to display but the idx value in the middle of the marker
  const textLabels = topics ? topics.map((topic: Topic) => topic.idx.toString()) : [];

  const handleScatterClick = (event: any) => {
    if (event.points && event.points.length > 0 && topics) {
      const pointIndex = event.points[0].pointIndex;
      const topic = topics[pointIndex] as Topic;
      // Toggle selection: if the same topic is clicked again, deselect it
      setSelectedTopic(selectedTopic && selectedTopic.id === topic.id ? null : topic);
    }
  };

  const handleTimePeriodChange = (event: SelectChangeEvent<string>) => {
    setTimePeriod(event.target.value as string);
  };

  const aggregateData = (data: TopicTimeseries[], period: string) => {
    const aggregated: { [key: string]: number } = {};
    data.forEach(({ date, count }) => {
      const d = new Date(date);
      if (isNaN(d.getTime())) {
        console.warn(`Invalid date encountered: ${date}`);
        return; // skip this entry
      }
      const key = d.toISOString().slice(0, period === 'month' ? 7 : 10); // YYYY-MM or YYYY-MM-DD
      if (!aggregated[key]) {
        aggregated[key] = 0;
      }
      aggregated[key] += count;
    });
    return {
      x: Object.keys(aggregated),
      y: Object.values(aggregated)
    };
  };

  const { x: lineX, y: lineY } = timeseriesData ? aggregateData(timeseriesData, timePeriod) : { x: [], y: [] };

  const handleTimeseriesClick = (event: any) => {
    if (event.points && event.points.length > 0) {
      const clickedDate = String(event.points[0].x);
      setSelectedDate(selectedDate === clickedDate ? null : clickedDate);
    }
  };

  const filteredTopDocs =
    selectedDate && topDocs
      ? topDocs.filter((doc: any) => {
          const docDate = new Date(doc.feedback_last_message_sent_at).toISOString().slice(0, 10);
          return docDate === selectedDate;
        })
      : topDocs;

  // Highlight the selected date marker by computing marker colors for the timeseries plot.
  const timeseriesMarkerColors = lineX.map((date) =>
    date === selectedDate ? 'red' : 'orange' 
  );

  // State to track which document is expanded
  const [expandedDoc, setExpandedDoc] = useState<string | null>(null);
  const handleToggleDoc = (docId: string) => {
    setExpandedDoc(expandedDoc === docId ? null : docId);
  };

  return (
    <StandardBase>
      <Box sx={{ padding: 2 }}>
        {topicsLoading ? (
          <div>Loading topics...</div>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>Textanalys</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Paper sx={{ flex: 2, padding: 2 }}>
                <Typography variant="h6" gutterBottom>Ämnen</Typography>
                <Plot
                  data={[
                    {
                      x: xData,
                      y: yData,
                      type: 'scatter',
                      mode: 'markers+text',
                      marker: { size: markerSizes, color: markerColors, opacity: 0.5 },
                      text: textLabels,
                      hovertext: hoverTexts,
                      textposition: 'middle center',
                      hoverinfo: 'text'
                    }
                  ]}
                  layout={{ 
                    width: 600, 
                    height: 400, 
                    clickmode: 'event+select',
                    margin: { t: 20 },
                    showlegend: false,
                    xaxis: { showgrid: false, visible: false },
                    yaxis: { showgrid: false, visible: false }
                  }}
                  config={{ displayModeBar: false }}
                  onClick={handleScatterClick}
                />
              </Paper>
              <Paper sx={{ width: 400, flexShrink: 0, padding: 2, maxHeight: 400, overflow: 'auto' }}>
                <Typography variant="h6" gutterBottom>Top Feedbacks</Typography>
                {!selectedTopic ? (
                  <div>Välj ett ämne för att se toppfeedback.</div>
                ) : docsLoading ? (
                  <div>Laddar feedback...</div>
                ) : (
                  <List>
                    {filteredTopDocs &&
                      filteredTopDocs.map((doc: TopicDocumentWithFeedback) => (
                        <div key={doc.id}>
                          <ListItem button onClick={() => handleToggleDoc(doc.id)}>
                            <ListItemText
                              primary={doc.feedback_title}
                              secondary={
                                <>
                                  {doc.feedback_last_message_content}
                                  <br />
                                  {new Date(doc.feedback_last_message_sent_at)
                                    .toISOString()
                                    .replace('T', ' ')
                                    .substring(0, 19)}
                                </>
                              }
                            />
                          </ListItem>
                          <Collapse in={expandedDoc === doc.id} timeout="auto" unmountOnExit>
                            <Typography variant="body2" sx={{ pl: 4 }}>
                              {doc.feedback_last_message_content}
                            </Typography>
                          </Collapse>
                        </div>
                      ))}
                  </List>
                )}
              </Paper>
            </Box>
            <Paper sx={{ padding: 2, marginTop: 2 }}>
              <Typography variant="h6" gutterBottom>Feedback över tid</Typography>
              <FormControl variant="outlined" sx={{ minWidth: 120, marginBottom: 2 }}>
                <InputLabel id="time-period-label">Period</InputLabel>
                <Select
                  labelId="time-period-label"
                  value={timePeriod}
                  onChange={handleTimePeriodChange}
                  label="Period"
                >
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                </Select>
              </FormControl>
              {timeseriesLoading ? (
                <div>Laddar tidsserier...</div>
              ) : (
                <Plot
                  data={[
                    {
                      x: lineX,
                      y: lineY,
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: timeseriesMarkerColors },
                      line: { color: 'orange', opacity: 0.5 }
                    }
                  ]}
                  layout={{ width: 800, height: 300, margin: { t: 20 }, showlegend: false, xaxis: { type: 'date' } }}
                  config={{ displayModeBar: false }}
                  onClick={handleTimeseriesClick}
                />
              )}
            </Paper>
          </>
        )}
      </Box>
    </StandardBase>
  );
};

export default TextAnalyticsPage; 