import { useQuery } from '@tanstack/react-query';
import { ax } from '../Utils';

interface TopicTimeseriesParams {
  topic_id?: string;
}

export const useTopicTimeseries = ({ topic_id }: TopicTimeseriesParams) => {
  return useQuery({
    queryKey: ['topicTimeseries', topic_id],
    queryFn: async () => {
      const url = topic_id ? `/topic/timeseries/?topic_id=${topic_id}` : '/topic/timeseries/';
      const response = await ax.get(url);
      return response.data;
    }
  });
}; 