import { DataModel } from "../Types";
import { ax } from "../Utils";
import { useQuery } from "@tanstack/react-query";

export const useCustomDataModels = () => {
  return useQuery<DataModel[], Error>({
    queryKey: ['custom_data_model', 'all'],
    queryFn: () => ax.get('/custom_data_model/all/').then(res => res.data),
    refetchOnReconnect: true,
  });
};